import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form';
import Map from './section-components/map';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { Helmet } from 'react-helmet';
import OfficeInfo from './section-components/office-info';

const ContactV1 = () => {
    return <div>
        <Helmet>
            <title>Hoyee Property Group | Contact | Australia</title>
        </Helmet>

        <Navbar />
        <PageHeader headertitle="Contact Us" subheader="Contact" />
        {/*<ContactInfo />*/}
        <OfficeInfo /> {/* Include the OfficeInfo component */}
        <ContactForm />
        {/*<Map />*/}
        <CallToActionV1 />
        <Footer />
    </div>
}

export default ContactV1

