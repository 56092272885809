import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import { Helmet } from 'react-helmet';
import './hoyee-projects.css';

const HoyeeProjects = () => {
  let publicUrl = process.env.PUBLIC_URL + '/'

  return <div>
    <Helmet>
      <title>Hoyee Property Group | Hoyee Projects | Australia</title>
    </Helmet>

    <Navbar/>
    <PageHeader headertitle="" subheader="Hoyee Projects"/>

    <>
      <div className="ltn__blog-details-wrap">
        <div className="ltn__page-details-inner ltn__blog-details-inner">
          {/*<h1 className="ltn__blog-title" style={{ textAlign: 'center' }}>Hoyee Projects</h1>*/}
          <div className={'hoyee-project-banner'}>
            <img
              src={publicUrl + "assets/img/banner/hoyee_projects_banner.png"}
              alt="Image"
              style={{
                display: 'block',
                margin: 'auto',
                maxWidth: '100%',
                height: 'auto'
              }}
            />
          </div>
          <p>
            Hoyee Projects specializes in overseeing the sales and marketing of residential projects, guiding them from
            inception to completion. We take immense pride in our outstanding track record of delivering exceptional
            results for our valued clients. This success is achieved through the implementation of time-tested
            strategies, attention to detail, and a deep understanding of the local and overseas market.
          </p>
          <p>
            Our Projects team collaborates closely with our developer partners to devise optimal design, branding,
            marketing, and sales release strategies. This collaborative approach aims to maximize both pricing and rates
            of sale for off-plan projects.
          </p>
          <p>
            At Hoyee Projects, we promote projects on both a national and international scale. We frequently collaborate
            with our extensive network of local and international partners, to ascertain the best market pricing and
            target audience for each project. Our unwavering commitment lies in maximizing the value of our client’s
            projects and meeting their needs with the highest standards of service.
          </p>
          <h3 style={{ textAlign: 'center' }}>
            Why Choose Hoyee Projects?
          </h3>
          <p>
            For residential aspirations of every scale, Hoyee Projects excels in the art of property marketing, from
            conceptual designs to completed homes. By partnering closely with developers and drawing inspiration from
            architectural, interior, and landscape concepts, we leverage our deep market insights to engage potential
            buyers effectively. We bring innovative concepts to fruition, comprehending precisely what captivates a
            project's target demographic, and then customize messaging and marketing strategies to stand out in a
            competitive environment. The impact is unmistakable—just take a drive past a Hoyee sign.
          </p>
          <p>
            At Hoyee Projects, we are not just an owner-led enterprise but also possess a robust track record in project
            marketing. Our seasoned team of property and marketing experts possess unparalleled knowledge of the local
            residential market. We leverage our extensive local and offshore network partners and their client base in
            conjunction with our own resources. With an active pool of buyers from our projects, residential, and
            international databases, we ensure that your project is marketed to precisely the right audience.
          </p>
          <p>
            Our achievements are rooted in employing proven methodologies, paying attention to detail, and possessing an
            acute grasp of the local and overseas market landscape. We remain steadfast in our pursuit to redefine and
            advance project marketing, all for the profitability and success of our clients. Our fundamental approach
            centers on comprehending our client’s unique needs and channeling the collective experience of our team to
            achieve the desired outcomes.
          </p>
        </div>
      </div>
    </>
    <Footer/>
  </div>
}

export default HoyeeProjects
