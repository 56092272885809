import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form';
import Map from './section-components/map';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import {Link} from "react-router-dom";
import Comments from "./blog-components/comments";
import { Helmet } from 'react-helmet';

const SettlementManagement = () => {
  let publicUrl = process.env.PUBLIC_URL + '/'

  return <div>
    <Helmet>
      <title>Hoyee Property Group | Settlement Management | Australia</title>
    </Helmet>

    <Navbar/>
    <PageHeader headertitle="" subheader="Settlement Management"/>

    <>
      <div className="ltn__blog-details-wrap">
        <div className="ltn__page-details-inner ltn__blog-details-inner">
          <h1 className="ltn__blog-title" style={{textAlign: "center"}}>Settlement Management</h1>
          {/*<div style={{ textAlign: 'center', width: '50%', margin: '0 auto' }}>*/}
          {/*  <img*/}
          {/*    src={publicUrl + "assets/img/banner/hoyee_projects_banner.png"}*/}
          {/*    alt="Image"*/}
          {/*    style={{*/}
          {/*      display: 'block',*/}
          {/*      margin: 'auto',*/}
          {/*      maxWidth: '100%',*/}
          {/*      height: 'auto'*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}

          <p>
          Hoyee Projects offers a comprehensive Settlement Management service, providing developers the opportunity to focus on their core development activities while entrusting the critical customer service aspect of settling their project to an expert.
          </p>
          <p>
          Each settlement program is planned and executed with precision, ensuring a seamless process for all parties involved. Our dedicated team at Hoyee Projects is committed to nurturing each purchaser through the settlement journey, maximizing successful outcomes through a range of tailored services, including:
          </p>
          <ul>
            <li>Thorough database cleansing</li>
            <li>Regular construction updates</li>
            <li>Assess settlement risk carefully of each purchaser</li>
            <li>Update and educate purchasers on settlement processes and milestones</li>
            <li>Conduct final inspections with purchasers and promptly reporting any defects to the builder</li>
            <li>Coordinate and conduct valuation inspections</li>
            <li>Collaborate with purchasers identified as having settlement risk to find alternative finance options and guide them through to settlement</li>
            <li>Efficient management of key handovers</li>
            <li>Providing developers with up-to-date reporting on settlement progress</li>
          </ul>
          <p>
          Drawing on our extensive knowledge and expertise, Hoyee Projects specializes in settling, leasing, and managing newly constructed development. Our team has achieved notable success in executing highly effective settlement programs in recent years.
          </p>
          <p>
            For inquiries about our Settlement Management services, please don't hesitate to reach out to <strong>Chloe Zang</strong> at <strong>0430 034 988</strong>. We look forward to discussing how we can assist with your project's settlement needs.
          </p>
        </div>
      </div>
    </>
    <ContactForm/>

    {/*<ContactInfo/>*/}
    {/*<Map />*/}
    {/*<CallToActionV1 />*/}
    <Footer/>
  </div>
}

export default SettlementManagement
