import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar';
import ShogGrid from './shop-components/shop-grid-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import SearchFormOnListPage from "./section-components/search-form-on-list-page";
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

/**
 * @typedef {Object} ShopGridProps
 * @property {'sell' | 'sold' | 'buy' | 'rent'} type
 * @property {Object} searchOptions
 * @property {string} searchOptions.propertyType
 * @property {string} searchOptions.bedrooms
 * @property {string} searchOptions.bathrooms
 * @property {string} searchOptions.carSpaces
 * @property {string} searchOptions.maxPrice
 * @property {Function} onFetchComplte
 */

/**
 * Note: This component is used for all properties except rent properties. The reason to
 * separate this component from ShopGridRent is that to generate a new instance of filter form, so that
 * it reset all selects to default option (Check note on ShopGridRent).
 *
 * According to feature of `nice-select`, the status and options of filter form will be kept if its component remains in one page(parent component).
 * So that when switch to buy and sold, the filter form will keep the status.
 * @param {ShopGridProps} props
 */
const ShopGrid_V1 = (props) => {
    console.log('shop-grid >> props.type', props.type);
    const keyword = props.type === 'sell' ? 'Sell' : props.type === 'sold' ? 'Sold' : props.type === 'buy' ? 'Buy' : props.type === 'rent' ? 'Rent' : 'Properties';

  const [searchOptions, setSearchOptions] = React.useState({
    propertyType: 'All',
    bedrooms: 'Bed',
    bathrooms: 'Bath',
    carSpaces: 'Car',
    maxPrice: 'Max Price',
  });

  const handleSearchOptionsChange = (newOptions) => {
    setSearchOptions({...searchOptions, ...newOptions});
  };

  const [fetchComplete, setFetchComplete] = useState(false);
  const handleFetchComplete = () => {
    setFetchComplete(true);
  }

  const [searchType, setSearchType] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    setSearchType(params.get('type'));
    setSearchQuery(params.get('query'));
  }, [location]);

  useEffect(() => {
    console.log('useEffect >> props.type', props.type);
    return () => {
    };
  }, [props.type]);

    return <div>
        <Helmet>
            <title>Hoyee Property Group | {keyword} | Australia</title>
        </Helmet>
        <Navbar />
        {/*<PageHeader headertitle="Properties" />*/}
        <SearchFormOnListPage
          type={props.type}
          searchType={searchType}
          searchOptions={searchOptions}
          onSearchOptionsChange={handleSearchOptionsChange}
          fetchComplete={fetchComplete}
        />
        <ShogGrid
          type={props.type}
          searchOptions={searchOptions}
          onFetchComplte={handleFetchComplete}
        />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default ShopGrid_V1

