import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ProductSlider from './shop-components/product-slider-v1';
import Footer from './global-components/footer';
import { Link, useLocation } from 'react-router-dom';
import Contact from "./contact";
import ContactForm from "./section-components/contact-form";
import { createMarkup } from '../utility';
import './project-details.css';

const ProjectDetails = () => {
  let publicUrl = process.env.PUBLIC_URL + '/'

  /**
   * @type {Project[]}
   */
  const projects = [
    {
      name: 'CAMBER-WELL',
      location: '1269 Toorak Road Camberwell VIC 3124',
      description: 'This architecturally designed modern 6 townhouses offer the ultimate in eco-friendly and contemporary living with privacy and security of a gated estate, and features high-end fixtures and cutting-edge design throughout, embracing the European lifestyle of Camberwell \n' +
        '\n' +
        'Radiant living set across the ground floor offers the feel of a home with the freedom of a low-maintenance lot on its own title. It\'s an effortless setting that frees you to indulge in life\'s finer things. Take advantage of esteemed private schools and universities, just metres from Hartwell Primary School and zoned for Camberwell High School, the tram is at your doorstep for a swift trip to Deakin University, Presbyterian Ladies\' College, Camberwell/Kew private schools and Camberwell Junction. An easy walk to the city-bound train, and stroll down the street to enjoy buzzing cafes at Burwood Village and Hartwell Village.\n',
      agentDetails: 'NOW SELLING\n' +
        'INSPECTION BY APPOINTMENT\n' +
        'Chloe Zang 0430 034 988\n' +
        'Gavin Liu 0406 679 777\n',
      enquire: 'To enquire about this property marketed by Hoyee Projects Team, call 0430 034 988 or email info@hoyee.com.au\n',
      imageFilenames: [
        'assets/img/projects/camber_well_1.jpg',
        'assets/img/projects/camber_well_2.jpg',
        'assets/img/projects/camber_well_3.jpg',
        'assets/img/projects/camber_well_4.jpg',
        'assets/img/projects/camber_well_5.jpg',
        'assets/img/projects/camber_well_6.jpg',
        'assets/img/projects/camber_well_7.jpg',
      ],
    },
    {
      name: 'The Jersey',
      location: '29 Jersey Parade Carnegie VIC 3163',
      description: 'A complex of 10 magnificent residences that embody the epitome of contemporary living, offering a luxurious, low-maintenance lifestyle right at your fingertips. Nestled in the prestigious "The Jersey" boutique development, this architecturally designed gem is the perfect sanctuary for young couples, families, professionals on the go, or astute investors. Close to the new elevated Carnegie Railway Station.',
      agentDetails: 'NOW SELLING\n' +
        'INSPECTION BY APPOINTMENT\n' +
        'Chloe Zang 0430 034 988\n' +
        'Gavin Liu 0406 679 777\n',
      enquire: 'To enquire about this property marketed by Hoyee Projects Team, call 0430 034 988 or email info@hoyee.com.au',
      imageFilenames: [
        'assets/img/projects/the_jersey_1.jpg',
        'assets/img/projects/the_jersey_2.jpg',
        'assets/img/projects/the_jersey_3.jpg',
        'assets/img/projects/the_jersey_4.jpg',
        'assets/img/projects/the_jersey_5.jpg',
        'assets/img/projects/the_jersey_6.jpg',
        'assets/img/projects/the_jersey_7.jpg',
      ],
    },
  ]

  /**
   * @type {Location<H.LocationState>}
   */
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const name = query.get('name')
  const project = projects.find(p => p.name === name);

  return <div>
    <Navbar />
    {/*<PageHeader headertitle="Project Details" customclass="mb-0" />*/}
    <>
      <div className="ltn__shop-details-area pb-10">

        <div className="container">

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                <div>
                  <img src={publicUrl + project.imageFilenames[0]} alt="Image" style={{display: 'block', width: '100%', height: 'auto'}} />
                </div>

                <h1>{project.name}</h1>
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-pin" style={{verticalAlign: 'middle'}}/>
                  </span>
                  {project.location}
                </label>
                <h4 className="title-2">DESCRIPTION</h4>
                {createMarkup(project.description)}
                <h4 className="title-2">AGENT DETAILS</h4>
                {createMarkup(project.agentDetails)}
                <h4 className="title-2">ENQUIRE ABOUT THIS PROPERTY</h4>
                {createMarkup(project.enquire)}
                <h4 className="title-2">GALLERY</h4>
                <div className="ltn__property-details-gallery mb-30">
                  <div className="row">
                    {
                      project.imageFilenames.slice(1).map((imageFilename, index) => {
                        return (
                          <div className="col-md-6" key={`image-${index}`}>
                            <a href={publicUrl + imageFilename} data-rel="lightcase:myCollection">
                              <img className="mb-30" src={publicUrl + imageFilename} alt="Image"/>
                            </a>
                          </div>
                        )
                      })
                    }
                    {/*<div className="col-md-6">*/}
                    {/*  <a href={publicUrl + "assets/img/others/14.jpg"} data-rel="lightcase:myCollection">*/}
                    {/*    <img className="mb-30" src={publicUrl + "assets/img/others/14.jpg"} alt="Image"/>*/}
                    {/*  </a>*/}
                    {/*</div>*/}
                  </div>
                </div>
                {/*<h4 className="title-2">Project Video</h4>*/}
                {/*<div className="ltn__video-bg-img ltn__video-popup-height-500 bg-overlay-black-50 bg-image mb-60"*/}
                {/*     data-bs-bg={publicUrl + "assets/img/others/5.jpg"}>*/}
                {/*  <a className="ltn__video-icon-2 ltn__video-icon-2-border---"*/}
                {/*     href="https://www.youtube.com/embed/_Q09ditbJnk?autoplay=1&showinfo=0"*/}
                {/*     data-rel="lightcase:myCollection">*/}
                {/*    <i className="fa fa-play"/>*/}
                {/*  </a>*/}
                {/*</div>*/}
                {/*<h4 className="title-2">Location</h4>*/}
                {/*<div className="property-details-google-map mb-60">*/}
                {/*  <iframe*/}
                {/*    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25231.483211442715!2d145.02514530635693!3d-37.768112857145276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad64395e191a311%3A0x5045675218cd5e0!2sIvanhoe%20VIC%203079!5e0!3m2!1sen!2sau!4v1678974248095!5m2!1sen!2sau"*/}
                {/*    width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0}/>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>

    </>

    {/*<ProductSlider />*/}
    <ContactForm title={'Enquiry about our project'} />
    <Footer />
  </div>
}

export default ProjectDetails

