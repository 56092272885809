// src/components/section-components/office-info.js
import React from 'react';
import Social from '../section-components/social';

const OfficeInfo = () => {
  return (
    <div className="office-info-area pt-70 pb-90">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__form-box contact-form-box box-shadow white-bg">
              <h4 className="title-2">Contact Us</h4>
              <div className="office-info">
                <div className="row">
                  <div className="col-md-6">
                    <p><b>HEAD OFFICE:</b> Level 10 / 350 Collins Street, Melbourne VIC 3000 Australia</p>
                    <p><a href="tel:+0123-456789">+61 3 8743 3342</a></p>
                    <p><a href="mailto:example@example.com">Info@hoyee.com.au</a></p>
                  </div>
                  <div className="col-md-6">
                    <p><b>Point Cook Office:</b> Level 2, 42 / 22-30 Wallace Ave, Point Cook VIC 3030 Australia</p>
                    <p><b>Zhejiang, China Office:</b> No.35, District A, Textile City, Datang Town, Zhuji, Shaoxing City, Zhejiang, China</p>
                    <p><b>Jiangsu, China Office:</b> No.403, Legend Holdings Lianhong New Material Innovation R&D Building, Changzhou Science and Education City. Changzhou, Jiang Su, China</p>
                  </div>
                </div>
                <Social />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfficeInfo;
