import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';
import Copyright from './copyright';

class Footer_v1 extends Component {

    componentDidMount() {

    	const $ = window.$;

        let publicUrl = process.env.PUBLIC_URL+'/'
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/main.js";

        document.body.appendChild(minscript);

         $('.go-top').find('a').on('click', function () {

			$(".quarter-overlay").fadeIn(1);

				$(window).scrollTop(0);

			setTimeout(function(){
			    	$(".quarter-overlay").fadeOut(300);
				}, 800);

        });


		$(document).on('click','.theme-btn-1 ', function(){
            $( 'div' ).removeClass( 'modal-backdrop' );
            $( 'div' ).removeClass( 'show' );
            $( 'div' ).removeClass( 'fade' );
			$('body').attr("style", "");
        });
    }

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = "Footer logo"

        return (
				<footer className="ltn__footer-area  ">
				  <div className="footer-top-area  section-bg-2 plr--5">
				    <div className="container-fluid">
				      <div className="row">
				        <div className="col-xl-6 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-about-widget">
				            {/*<div className="footer-logo">*/}
				              {/*<div className="site-logo">*/}
				              {/*  <img src={publicUrl+"assets/img/logo.png"} alt="Logo" />*/}
				              {/*</div>*/}
				            {/*</div>*/}
				            {/*<p>Whether you’re looking for a property as a new home or you have*/}
										{/*	land you want to do something with, we’d love to hear from you.</p>*/}
				            <div className="footer-address">
				              <ul>
				                <li>
				                  <div className="footer-address-icon">
				                    <i className="icon-placeholder" />
				                  </div>
				                  <div className="footer-address-info">
														<b>HEAD OFFICE:</b>
				                    <p>Level 10 / 350 Collins Street, Melbourne VIC 3000 Australia</p>
				                  </div>
				                </li>
				                <li>
				                  <div className="footer-address-icon">
				                    <i className="icon-call" />
				                  </div>
				                  <div className="footer-address-info">
				                    <p><a href="tel:+0123-456789">+61 3 8743 3342</a></p>
				                  </div>
				                </li>
				                <li>
				                  <div className="footer-address-icon">
				                    <i className="icon-mail" />
				                  </div>
				                  <div className="footer-address-info">
				                    <p><a href="mailto:Info@hoyee.com.au">Info@hoyee.com.au</a></p>
				                  </div>
				                </li>
				              </ul>
				            </div>
				            <div className="ltn__social-media mt-20">
						    	<Social />
				            </div>
				          </div>
				        </div>
								<div className="col-xl-6 col-md-6 col-sm-6 col-12">
									<div className="footer-widget footer-menu-widget clearfix">
									{/*<h4 className="footer-title">Branches</h4>*/}
									<div className="footer-address">
										<ul>
											<li>
												<div className="footer-address-icon">
													<i className="icon-placeholder" />
												</div>
												<div className="footer-address-info">
													<b>Point Cook Office: </b>
													<p>
														Level 2, 42 / 22-30 Wallace Ave, Point Cook VIC 3030 Australia
													</p>
												</div>
											</li>
											<li>
												<div className="footer-address-icon">
													<i className="icon-placeholder" />
												</div>
												<div className="footer-address-info">
													<b>Zhejiang, China Office: </b>
													<p>
														No.35, District A, Textile City, Datang Town, Zhuji, Shaoxing City, Zhejiang, China
													</p>
												</div>
											</li>
											<li>
												<div className="footer-address-icon">
													<i className="icon-placeholder" />
												</div>
												<div className="footer-address-info">
													<b>Jiangsu, China Office: </b>
													<p>
														No.403, Legend Holdings Lianhong New Material Innovation R&D Building,
														Changzhou Science and Education City. Changzhou, Jiang Su, China
													</p>
												</div>
											</li>
										</ul>
									</div>
									</div>
								</div>
				        {/*<div className="col-xl-2 col-md-6 col-sm-6 col-12">*/}
				        {/*  <div className="footer-widget footer-menu-widget clearfix">*/}
				        {/*    <h4 className="footer-title">Company</h4>*/}
				        {/*    <div className="footer-menu go-top">*/}
				        {/*      <ul>*/}
				        {/*        <li><Link to="/about">About</Link></li>*/}
				        {/*        <li><Link to="/contact">Contact</Link></li>*/}
				        {/*        <li><Link to="/contact">Contact us</Link></li>*/}
				        {/*      </ul>*/}
				        {/*    </div>*/}
				        {/*  </div>*/}
				        {/*</div>*/}
				        {/*<div className="col-xl-2 col-md-6 col-sm-6 col-12">*/}
				        {/*  <div className="footer-widget footer-menu-widget clearfix">*/}
				        {/*    <h4 className="footer-title">Services</h4>*/}
				        {/*    <div className="footer-menu go-top">*/}
				        {/*      <ul>*/}
				        {/*        <li><Link to="/login">Login</Link></li>*/}
				        {/*        <li><Link to="/about">Terms &amp; Conditions</Link></li>*/}
				        {/*      </ul>*/}
				        {/*    </div>*/}
				        {/*  </div>*/}
				        {/*</div>*/}
				      </div>
				    </div>
				  </div>
				  <Copyright />
				</footer>
        )
    }
}


export default Footer_v1
