import { fetchImageSize, fetchUsers } from './api';
import xml2js from 'xml2js';
import React from 'react';

export function extractLeasingPropertyAddress(data) {
  if (!data || !data.address || !data.address[0]) {
    return "";
  }

  const addressData = data.address[0];
  let address = "";

  if (addressData.subNumber && addressData.subNumber[0] && (!addressData.$ || addressData.$.display === 'yes')) {
    address += addressData.subNumber[0] + '/';
  }

  if (addressData.streetNumber && addressData.streetNumber[0] && (!addressData.$ || addressData.$.display === 'yes')) {
    address += addressData.streetNumber[0] + ' ';
  }

  if (addressData.street && addressData.street[0] && (!addressData.$ || addressData.$.display === 'yes')) {
    address += addressData.street[0] + ', ';
  }

  if (addressData.suburb && addressData.suburb[0] && addressData.suburb[0]._ && (!addressData.suburb[0].$ || addressData.suburb[0].$.display === 'yes')) {
    address += addressData.suburb[0]._ + ', ';
  }

  if (addressData.state && addressData.state[0] && (!addressData.$ || addressData.$.display === 'yes')) {
    address += addressData.state[0] + ' ';
  }

  if (addressData.postcode && addressData.postcode[0] && (!addressData.$ || addressData.$.display === 'yes')) {
    address += addressData.postcode[0] + ', ';
  }

  if (addressData.country && addressData.country[0] && (!addressData.$ || addressData.$.display === 'yes')) {
    address += addressData.country[0];
  }

  return address;
}

/**
 * Outputs:
 *  {
 *    name: 'H.PM Leasing',
 *    telephones: { 'default': number, 'mobile': number }
 *    email: 'h.pm@hoyee.com.au'
 *  }
 * @param data
 * @returns {{name: string, telephones: *[], email: string}}
 */
export function extractLeasingPropertyContact(data) {
  let contact = {
    name: null,
    telephones: {},
    email: null
  };

  if (!data.listingAgent || !data.listingAgent[0]) {
    return contact;
  }

  const agent = data.listingAgent[0];

  // Extract name
  if (agent.name && agent.name[0]) {
    contact.name = agent.name[0];
  }

  // Extract telephone numbers
  if (agent.telephone) {
    agent.telephone.forEach(tel => {
      if (typeof tel === 'string') {
        contact.telephones.default = tel;
      } else if (tel._ && tel.$ && tel.$.type) {
        contact.telephones[tel.$.type] = tel._;
      }
    });
  }

  // Extract email
  if (agent.email && agent.email[0]) {
    contact.email = agent.email[0];
  }

  return contact;
}

export function extractLeasingPropertyCarSpaces(data) {
  if (!data || !data.features[0]) {
    return '0';
  }

  if (data.features[0].garages[0] !== '') {
    return data.features[0].garages[0];
  }
  if(data.features[0].carports[0] !== '') {
    return data.features[0].carports[0];
  }

  return '0'
}

export function extractLeasingPropertyRent(data) {
  if (!data || !data.rent[0] || data.rent[0]._ === '' || data.rent[0].$.display !== 'yes') {
    return 'Contact Agent';
  }

  let period = data.rent[0].$.period.toLowerCase();
  if(period === 'weekly') {
    period = 'week';
  } else if(period === 'monthly') {
    period = 'month';
  } else if (period === 'annually') {
    period = 'year';
  }

  return `$${data.rent[0]._} / ${period}`;
}

export function extractLeasingPropertyCategory(data) {
  if (!data || !data.category || !data.category[0]) {
    return 'Unknown';
  }

  return data.category[0].$.name;
}

export async function filterLeasingImagesBySize(propertiesLeasing, size = 4.5) {
  for (let property of propertiesLeasing) {
    // console.log('shop-grid-v1 >> property:', extractLeasingPropertyAddress(property));
    // console.log('shop-grid-v1 >> urls:', property.images[0].img.map(img => img.$.url));
    if (property.images && property.images[0] && property.images[0].img) {
      // Filter images based on size
      const filteredImages = [];
      for (let img of property.images[0].img) {
        if (img.$ && img.$.url) {
          // console.log('url', img.$.url)
          const imageSize = await fetchImageSize(img.$.url);
          // console.log('imageSize', imageSize);
          // console.log('imageSize type', typeof imageSize.size);
            if (imageSize
              && imageSize.size
              && typeof imageSize.size === 'number'
              && imageSize.size > 0
              && imageSize.size <= size * 1024 * 1024) { // Assuming size is returned in bytes
                filteredImages.push(img);
            }
        }
      }
      property.images[0].img = filteredImages;
      // console.log('shop-grid-v1 >> filtered urls:', property.images[0].img.map(img => img.$.url));
    }
  }

  return propertiesLeasing;
}


export const parseToHTML = (text) => {
  const html = text.split('\n\n').map(paragraph =>
    `<p>${paragraph.replace(/\n/g, '<br>')}</p>`).join('');

  return html;
}

/**
 * Generate proper HTML Element by give text.
 * @param text
 * @returns {Element}
 */
export const createMarkup = (text) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: parseToHTML(text) }}/>
  )
};

export const fetchProperties = async (action, params) => {
  // Initialize properties and fetchedUsers array
  const properties = [];
  const fetchedUsers = [];

  // Fetching users
  try {
    const response = await fetchUsers({ pagesize: 50 });
    fetchedUsers.push(...response.items);
  } catch (error) {
    console.log(error);
  }

  if (fetchedUsers.length === 0) {
    console.log('Do not have users');
    return;
  }

  try {
    let page = 1;

    while (page) {
      const finalParams = { page, ...params };
      const response = await action(finalParams);
      console.log('fetchProperties >> response', response);

      if (!response.type) {
        // Sales API response contains items property
        properties.push(
          ...response.items.filter((item) =>
            item.contactStaff.some((staff) =>
              fetchedUsers.some((user) => user.id === staff.id)
            )
          )
        );
        page = response.urls.next ? page + 1 : null;
      } else {
        console.log('fetchProperties >> page', page, ' items', response.items);

        page = response.urls.next ? page + 1 : null;

        if (response.items) {
          const data = response.items;
          console.log('fetchProperties >> data');
          const parser = new xml2js.Parser();
          for (let xmlStr of data) {
            parser.parseString(xmlStr, (err, result) => {
              if (err) {
                console.error("Error parsing XML:", err);
              } else {
                console.log('fetchProperties >> result', result)
                result.root.document.forEach(doc => {
                  const address = extractLeasingPropertyAddress(doc.rental[0]);
                  console.log('fetchProperties >> address', address, doc.rental[0])
                  const find = properties.find(p => extractLeasingPropertyAddress(p) === address);
                  !find && properties.push(doc.rental[0]);
                })
              }
            });
          }
        }
      }

      console.log('fetchProperties >> page', page, properties);
    }

    return properties;
  } catch (error) {
    console.log(error);
  }
};
