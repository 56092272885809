import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';

class Map extends Component {

  render() {

    let publicUrl = process.env.PUBLIC_URL + '/'

    return <div className="google-map mb-120">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8789321329223!2d144.96028757655918!3d-37.816304671974784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b496a8095f%3A0x736b2076306952e5!2sLevel%2010%2F350%20Collins%20St%2C%20Melbourne%20VIC%203000!5e0!3m2!1sen!2sau!4v1678975091829!5m2!1sen!2sau"
        width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0}/>
    </div>
  }
}

export default Map
