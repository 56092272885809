import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Slider from 'react-slick';
import './product-slider-v1.css';

/**
 * @typedef {Object} ProductSliderV1Props
 * @property {Photo[]} photos
 */

/**
 * @param {ProductSliderV1Props} props
 * @returns {Element}
 * @constructor
 */
const ProductSliderV1 = (props) => {
  let publicUrl = process.env.PUBLIC_URL + '/'

  /**
   * @type {Photo[]} photos
   */
  const [photos, setPhotos] = React.useState([null, null, null, null, null]);

  useEffect(() => {
      console.log('ProductSliderV1 >> props.photos', props.photos)
      if(props.photos !== null && props.photos !== undefined) {
          setPhotos(props.photos);
      }
  }, [props.photos]);

    function Arrow(props) {
        const { className, style, onClick, iconClass } = props;
        return (
            <div
                className={className}
                onClick={onClick}
                style={{ ...style }}
            >
                <i className={`fas ${iconClass}`} alt="Arrow Icon"></i>
            </div>
        );
    }

    const [isDragging, setIsDragging] = React.useState(false);

    const sliderSettings2 = {
        prevArrow: <Arrow iconClass={'fa-chevron-left'}/>,
        nextArrow: <Arrow iconClass={'fa-chevron-right'}/>,
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        draggable: true,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 575,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
    }


  return (
    <div className="ltn__img-slider-area mb-30">
      <div className="container-fluid">
        <div className="row ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
            <Slider {...sliderSettings2}>
            {photos.map((photo, index) => {
                console.log(index)
                return (
                <div className="col-lg-12" key={`slide-${index}`}>
                    {/*<a id={`anchor-${index}`} href={ photo ? photo.url : ''} data-rel="lightcase:myCollection">*/}
                        {
                            photo ? <img src={photo.url} className={'product-slider-img'}/> : <Skeleton height={433}/>
                        }
                    {/*</a>*/}
                </div>
              )})
            }
            </Slider>
        </div>
      </div>
    </div>
  )
}

export default ProductSliderV1
