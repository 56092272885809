import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form';
import Map from './section-components/map';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import {Link} from "react-router-dom";
import Comments from "./blog-components/comments";

const ProjectLeasing = () => {
  let publicUrl = process.env.PUBLIC_URL + '/'

  return <div>
    <Navbar />
    <PageHeader headertitle="" subheader="Projects Leasing" />

    <>
      <div className="ltn__blog-details-wrap">
        <div className="ltn__page-details-inner ltn__blog-details-inner">
          <h1 className="ltn__blog-title" style={{textAlign: "center"}}>Projects Leasing</h1>
          <p>We are specialists in Project Leasing.</p>

          <p>Having successfully implemented leasing strategies for some of Melbourne’s largest developments including The Precinct, The Hub, The Bank Apartments and Tiara, our capability is unsurpassed in bringing brand new multi unit developments to the rental market for the first time.</p>

          <p>We work closely with developers and settlement teams to ensure a seamless transition from off the plan sales to the ongoing life of a building.</p>
        </div>
      </div>
    </>
    <ContactForm />

    <ContactInfo />
    {/*<Map />*/}
    {/*<CallToActionV1 />*/}
    <Footer />
  </div>
}

export default ProjectLeasing
