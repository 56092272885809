import React, {Component} from 'react';
import './search-form-on-list-page.css'
import { useLocation } from 'react-router-dom';

class SearchFormOnListPage extends Component {
  handleChange = (event, name) => {
    const $ = window.$;
    const selectedValue = $(event.target)?.val();
    this.props.onSearchOptionsChange({ [name]: selectedValue });
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const $ = window.$;
    console.log('componentDidMount >> this.props.searchType', this.props.searchType)
    if(prevProps.searchType !== this.props.searchType) {
      if(this.props.searchType) {
        $(this.propertyTypeSelectRef).val(this.capitalizeFirstLetter(this.props.searchType));
      } else {
        $(this.propertyTypeSelectRef).val('All');
      }
      // this.props.onSearchOptionsChange({ ['propertyType']:  });
      $(this.propertyTypeSelectRef).niceSelect('update');
    }
  }

  // /**
  //  * Reset selects option to default when switch to buy and sold
  //  * @param prevProps
  //  * @param prevState
  //  * @param snapshot
  //  */
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const $ = window.$;
  //
  //   if(prevProps.type !== this.props.type) {
  //     $(this.selectRef).val('All');
  //     this.props.onSearchOptionsChange({ ['propertyType']: 'All' });
  //     $(this.selectRef).niceSelect('update');
  //   }
  // }

  render() {

    let publicUrl = process.env.PUBLIC_URL + '/'

    return <div className="ltn__car-dealer-form-area " id="filter-buy-sold">
      <div className="container" style={{paddingTop: "120px"}}>
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__car-dealer-form-tab">
              <div className="tab-pane fade active show" id="ltn__form_tab_1_1"
                   style={{display: "flex", justifyContent: "center", alignItems: "center"}}
              >
                <form action="#" className="ltn__car-dealer-form-box row" style={{width: '100%'}}>
                  <div className="ltn__car-dealer-form-item col-lg-3 col-md-3 col-sm-3 search-from-list-page-customize"
                       style={{minWidth: '20px'}}
                  >
                    <select
                      ref={(ref) => this.propertyTypeSelectRef = ref}
                      className="nice-select"
                      disabled={this.props.searchType}
                      style={{border: 'none !important'}}
                      onClick={(event) => {
                        this.handleChange(event, 'propertyType')
                      }}
                    >
                      <option>All</option>
                      <option>House</option>
                      <option>Apartment</option>
                      <option>Townhouse</option>
                      <option>Unit</option>
                      <option>Land</option>
                      <option>Commercial</option>
                      <option>Other</option>
                  </select>
                  </div>
                  <div className="ltn__car-dealer-form-item col-lg-2 col-md-2 col-sm-2 search-from-list-page-customize"
                       style={{minWidth: '20px'}}
                  >
                    <select
                      className="nice-select"
                      onClick={(event) => {
                        this.handleChange(event, 'bedrooms')
                      }}
                    >
                      <option>Bed</option>
                      <option>1+</option>
                      <option>2+</option>
                      <option>3+</option>
                      <option>4+</option>
                    </select>
                  </div>
                  <div className="ltn__car-dealer-form-item col-lg-2 col-md-2 col-sm-2 search-from-list-page-customize"
                       style={{minWidth: '20px'}}
                  >
                    <select
                      className="nice-select"
                      onClick={(event) => {
                        this.handleChange(event, 'bathrooms')
                      }}
                    >
                      <option>Bath</option>
                      <option>1+</option>
                      <option>2+</option>
                      <option>3+</option>
                    </select>
                  </div>
                  <div className="ltn__car-dealer-form-item col-lg-2 col-md-2 col-sm-2 search-from-list-page-customize"
                       style={{minWidth: '20px'}}
                  >
                    <select
                      className="nice-select"
                      onClick={(event) => {
                        this.handleChange(event, 'carSpaces')
                      }}
                    >
                      <option>Car</option>
                      <option>1+</option>
                      <option>2+</option>
                    </select>
                  </div>
                  <div className="ltn__car-dealer-form-item col-lg-3 col-md-3 col-sm-3 search-from-list-page-customize"
                       style={{minWidth: '20px'}}
                  >
                    <select
                      className="nice-select"
                      onClick={(event) => {
                        this.handleChange(event, 'maxPrice')
                      }}
                    >
                      <option>Max Price</option>
                      <option>$500,000</option>
                      <option>$800,000</option>
                      <option>$1,000,000</option>
                      <option>$1,500,000</option>
                      <option>$2,000,000</option>
                      <option>$2,500,000</option>
                      <option>$3,000,000</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  }

}

export default SearchFormOnListPage
