import React, { useEffect } from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner';
import SearchFormOnHomePage from './section-components/search-form-on-home-page';
import Aboutv1 from './section-components/about-v1';
import Counter from './section-components/counter-v1';
import AboutV2 from './section-components/about-v2';
import Featuresv1 from './section-components/features-v1';
import ProSlider from './section-components/product-slider-v1';
import Apartment from './section-components/apartment-v1';
import VideoV1 from './section-components/video-v1';
import Category from './section-components/category-v1';
import Testimonial from './section-components/testimonial-v1';
import BlogSlider from './blog-components/blog-slider-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import BrandV1 from "./section-components/brand-v1";
import { updateHeaders } from '../api';
import { Helmet } from 'react-helmet';

const Home_V1 = () => {
    // useEffect(() => {
    //     updateHeaders();
    // }, []);

    return <div>
        <Helmet>
            <title>Hoyee Property Group | Real Estate Services | Australia</title>
        </Helmet>

        <Navbar />
        <Banner />
        <SearchFormOnHomePage />
        {/*<Aboutv1 />*/}
        <Counter />
        {/*<AboutV2 />*/}
        <ProSlider />
        {/*<Featuresv1 customClass="ltn__feature-area pb-90 mb-120---"/>*/}
        {/*<Apartment />*/}
        {/*<VideoV1 />*/}
        {/*<Category />*/}
        <Testimonial />
        {/*<BlogSlider customClass="section-subtitle-2"/>*/}

        {/*<BrandV1/>*/}

        <CallToActionV1 />
        <Footer />
    </div>
}

export default Home_V1

