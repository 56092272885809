import { makeAutoObservable } from 'mobx';

class GlobalStore {
  leasingProperties = [];
  sellProperties = [];
  soldProperties = [];

  constructor() {
    makeAutoObservable(this);
  }

  // Action to set leasing properties
  setLeasingProperties(properties) {
    this.leasingProperties = properties;
  }

  // Action to add a new property
  addLeasingProperty(property) {
    this.leasingProperties.push(property);
  }

  // Getter to retrieve all leasing properties
  get allLeasingProperties() {
    return this.leasingProperties;
  }

  setSellProperties(properties) {
    this.sellProperties = properties;
  }

  setSoldProperties(properties) {
    this.soldProperties = properties;
  }

  // Action to add a new property
  addSellProperty(property) {
    this.sellProperties.push(property);
  }

  // Getter to retrieve all leasing properties
  get allSellProperties() {
    return this.sellProperties;
  }

  // Getter to retrieve all sold properties
  get allSoldProperties() {
    return this.soldProperties;
  }
}
export default GlobalStore;
