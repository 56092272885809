import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import { Link } from 'react-router-dom';
import CallToActionV1 from './section-components/call-to-action-v1';
import { Helmet } from 'react-helmet';
import { ReactComponent as QuestionMark } from '../icons/icon_question.svg';
import './renter-forms-and-resources.css';

const RenterFormsAndResources = () => {
  let publicUrl = process.env.PUBLIC_URL + '/'

  return (
    <div>
      <Helmet>
        <title>Hoyee Property Group | Renter Forms and Resources | Australia</title>
      </Helmet>

      <Navbar/>
      <PageHeader headertitle="" subheader="Property Management Services"/>

      <>
        <div className="ltn__blog-details-wrap">
          <div className="ltn__page-details-inner ltn__blog-details-inner">
            <h1 className="ltn__blog-title" style={{ textAlign: 'center' }}>
              PROPERTY MANAGEMENT
            </h1>
            <h5 style={{ textAlign: 'center', fontWeight: '300' }}>
              Renter Relevant Forms and Resources
            </h5>
            <div className="special-button" style={{ textAlign: 'center', paddingTop: '24px' }}>
              <a href="https://drive.google.com/drive/folders/1_zy5cvNJgcTCq-CiSlu337BnwTUqHdN3?usp=sharing"
                 style={{ fontWeight: 600 }}
                 target="_blank" rel="noopener noreferrer">
                View All Forms
              </a>
            </div>

            <div className={'intense-list'} style={{ paddingTop: '80px' }}>
              <div>
                <QuestionMark></QuestionMark>
                <h5>
                     PLEASE SELECT THE FOLLOWING RELEVANT FORM TO MEET YOUR REQUIREMENTS
                     </h5>

                <ul>
                <li>
                     Apply for a property –
                     <a href={'https://drive.google.com/file/d/1sRNkmOF2p909L-8YuW8TIz5VicQiitNx/view?usp=sharing'}
                     target="_blank" rel="noopener noreferrer"
                     >
                     &nbsp;click here
                     </a>
                     </li>

                <li>
                     Parental guarantee form for rental –
                     <a href={'https://drive.google.com/file/d/1dnd9MCjhsnSdEGwyMXU4_SKd9ipBy1uc/view?usp=sharing'}
                     target="_blank" rel="noopener noreferrer"
                     >
                     &nbsp;click here
                     </a>
                     </li>

                <li>
                     Vacating your current property –
                     <a href={'https://drive.google.com/file/d/1XL87_RbQc-vmlP2Fobo--dSPM0AZ1DuF/view?usp=sharing'}
                     target="_blank" rel="noopener noreferrer"
                     >
                     &nbsp;click here
                     </a>
                     </li>

                <li>
                     Maintenance request –
                     <a
                     href={'https://www.hoyee.com.au/faq#:~:text=Maintenance%C2%A0request%C2%A0%E2%80%93-,click%C2%A0here,-Pet%C2%A0request%C2%A0form'}
                     target="_blank" rel="noopener noreferrer"
                     >
                     &nbsp;click here
                     </a>
                     </li>

                <li>
                     Pet request form –
                     <a href={'https://drive.google.com/file/d/19uEX_Qu45IZI1OYtO1RRQ89VjgR6_FMQ/view?usp=sharing'}
                     target="_blank" rel="noopener noreferrer"
                     >
                     &nbsp;click here
                     </a>
                     </li>

                <li>
                     Communicate with your property manager –
                     <a
                     href={'https://id.propertyme.com/auth/?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dmemberportal.bff%26redirect_uri%3Dhttps%253A%252F%252Fmy.propertyme.com%252Fsignin-oidc%26response_type%3Dcode%26scope%3Dopenid%2520profile%2520offline_access%26code_challenge%3D-Un7DCxw9TtjJA73WSy4O9QJNdgvSd6hAN3hds-pr-4%26code_challenge_method%3DS256%26nonce%3D637926766276588390.MzA2ZDEzY2EtMDAyNS00ZWVjLWJmNzctN2ZjZDlmM2U1ZjlkMzcwZDNkMjUtNDBjMS00Y2VlLTkwODUtZjAyNDAxZDY1YTNj%26state%3DCfDJ8NEl4Jmxy5JJt8E_yaaW0YMY0KnPcVbKSyh5jYdNgCQ1yaUrSN0hfPvjUuh8pVc2d_8KYpFyX2RVnNt80GO6LjmMgSeCOXk2MX0-w70Fh7t3PqwjlFqWqTFBjbhRYEGijZ04rGb0AWIY89UgDmeV8zkwRG4wWy-h3uqufToOfurtNcOERT6Htzt-Orm3O_9eFFM5gcx_5KlQjElujnPxSDlFPUNTJnNlRgUZ7Fvk8FT2Pb2U1KE22E9s46TWS3h0rJiqLHIZf5B3_myogWiVSd9O0L5wb96frFUdRH815TTuQ4KSmme9DqgDFe-QGL9_nUD7ZvhOmnaLmVB3in_UoCFIod1o_rne9LBzRI2GuUFgjr1QZA_6GG17UYHJmebx4A%26x-client-SKU%3DID_NETSTANDARD2_0%26x-client-ver%3D6.10.0.0'}
                     target="_blank" rel="noopener noreferrer"
                     >
                     &nbsp;click here
                     </a>
                     </li>
                </ul>
              </div>

              <div className={'question-block'}>
              <QuestionMark></QuestionMark>
              <h5>
                HOW TO KNOW YOUR MAINTENANCE WORK CAN BE DEFINED AS “URGENT REPAIR”? ANY ONE OF THEFOLLOWINGS…….?
              </h5>

                <ul>
              <li>
                dangerous electrical fault
              </li>

              <li>
                the gas, electricity or water supply is not working
              </li>

              <li>
                an appliance, fitting or fixture that is not working and causes a lot of water to be wasted
              </li>

              <li>
                an essential service or appliance for hot water, water, cooking, heating, or laundering is not working
              </li>

              <li>
                a cooling appliance or service provided by the rental provider is not working
              </li>

              <li>
                a safety-related device, such as a smoke alarm or pool fence, is not working
              </li>

              <li>
                gas leak
              </li>

              <li>
                serious roof leak
              </li>

              <li>
                serious storm or fire damage
              </li>

              <li>
                burst water service
              </li>

              <li>
                blocked or broken toilet system (if you only have one toilet for use)
              </li>

              <li>
                flooding or serious flood damage
              </li>

              <li>
                any fault or damage in the property that makes it unsafe or insecure, including pests, mould or damp
                caused by or related to the building structure
              </li>

              <li>
                a serious problem with a lift or staircase.
              </li>
                </ul>
              </div>

              <div className={'question-block'}>
                <QuestionMark></QuestionMark>
                <h5>
                  DOES THIS URGENT REPAIR OCCUR MONDAY TO FRIDAY 9.00AM TO 5.30PM?
                </h5>
                <p><b>YES</b></p>
                <p>
                >>> Contact property manager department on 0411 984 500 to advise immediately
                </p>
                <p>
                >>> Email your property manager and if you receive an “autoreply – out of office or on annual leave”, please contact our office on 03 8743 3342
                </p>

                <p><b>NO</b></p>
                <p>
                >>> Contact one of the following technicians (outside of business hours, including public holidays),Remember to email your Property Manager to let them know that an urgent repair item occurred outside of business hours.
                </p>
                <ul>
                  <li>
                    <u>General Building/Handyman</u>
                    <table className="responsive-table">
                      <tbody>
                      <tr>
                        <td className="first-column">Sole Trader for Defects:</td>
                        <td>Tony Tu 0425 400 568</td>
                      </tr>
                      </tbody>
                      <tbody>
                      <tr>
                        <td className="first-column">General maintenance:</td>
                        <td>Cooper 0410 880 821</td>
                      </tr>
                      </tbody>
                    </table>
                  </li>
                  <li>
                    <u>Electrical, Plumbing/Gas, Heating & Cooling</u>
                    <table className="responsive-table">
                      <tbody>
                      <tr>
                        <td className="first-column">New Boda Service Group Electricity & gas & plumbing:</td>
                        <td>Bella 0426 665 985</td>
                      </tr>
                      </tbody>
                    </table>
                  </li>
                  <li>
                    <u>Smoke Alarms</u>
                    <table className="responsive-table">
                      <tbody>
                      <tr>
                        <td className="first-column">NBD Safety check:</td>
                        <td>Alex 0420 502 529</td>
                      </tr>
                      </tbody>
                    </table>
                  </li>
                </ul>

                <p>
                If any of these technicians are not available to approach your issue, you can engage another local tradesman to attend.
                </p>
                <p style={{fontWeight: 'bold', color: '#333' }}>
                FOR ALL NON URGENT MAINTENANCE ISSUES, PLEASE NOTIFY YOUR PROPERTY MANAGER VIA EMAIL AND THEY WILL GET BACK TO YOU DURING BUSINESS HOURS.
                </p>

                <p>
                  For other enquiries, please email&nbsp;
                  <a href="mailto:info@hoyee.com.au"
                     style={{textDecoration: 'underline'}}>
                   info@hoyee.com.au
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
      <CallToActionV1
        title={'Considering renting out your property?'}
        subtitle={'We can help you'}
        linkText={'Contact Us'}
        link={'/contact'}
      />
      <Footer/>
    </div>
  )
}

export default RenterFormsAndResources
