import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import {
  extractLeasingPropertyAddress,
  extractLeasingPropertyCarSpaces,
  extractLeasingPropertyContact, extractLeasingPropertyRent
} from '../../utility';
import { imageProxyUrl } from '../../api';

export const PropertySkeletonCardItem = ({ index }) => {
  return (
    <div className="col-lg-4 col-sm-6 col-12" key={`property-skeleton-card-sale-${index}`}>
      <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
        <div className="product-img">
          <Skeleton height={280} width={'100%'}/>
          <div className="real-estate-agent">
            <div className="agent-img">
              <Skeleton/>
            </div>
          </div>
        </div>
        <div className="product-info">
          <div className="product-badge">
            <ul>
              <Skeleton/>
            </ul>
          </div>
          <h2 className="product-title go-top">
            <Skeleton/>
          </h2>
          <div className="product-img-location">
            <ul>
              <li className="go-top">
                <Skeleton/>
              </li>
            </ul>
          </div>
          <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
            <li><Skeleton/></li>
            <li><Skeleton/></li>
            <li><Skeleton/></li>
          </ul>
          <div className="product-hover-action">
            {/* ... existing action buttons */}
          </div>
        </div>
        <div className="product-info-bottom">
          <div className="product-price">
            <Skeleton/>
          </div>
        </div>
      </div>
    </div>
  );
}

export function PropertySkeletonListItem({ index }) {
  return (
    <div className="col-lg-12" key={`property-skeleton-list-item-${index}`}>
      <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
        <div className="product-img">
          <Skeleton height={280} width={366}/>
        </div>
        <div className="product-info">
          <div className="product-badge-price">
            <div className="product-badge">
              <Skeleton/>
            </div>
            <div className="product-price">
              <Skeleton/>
            </div>
          </div>
          <h2 className="product-title go-top">
            <Skeleton/>
          </h2>
          <div className="product-img-location">
            <Skeleton/>
          </div>
          <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
            <li><Skeleton/></li>
            <li><Skeleton/></li>
            <li><Skeleton/></li>
          </ul>
        </div>
        <div className="product-info-bottom">
          <div className="real-estate-agent">
            <div className="agent-img">
              <Skeleton/>
            </div>
            <div className="agent-brief go-top">
              <Skeleton/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * @param property {Property}
 * @param index {number}
 * @returns {Element}
 * @constructor
 */
export const PropertyCardSale = React.memo(({ property, index, inspections }) => {
  const [upcomingInspections, setUpcomingInspections] = useState([]);

  useEffect(() => {
    if (inspections) {
      setUpcomingInspections(inspections);
    }
  }, [inspections]);

  if (!property) {
    return <PropertySkeletonCardItem index={index} />;
  }

  const publicUrl = process.env.PUBLIC_URL + '/';

  const {
    photos,
    displayAddress,
    displayPrice,
    bed,
    bath,
    carSpaces,
    carports,
    garages,
    openSpaces,
    contactStaff
  } = property;

  const contact = useMemo(() => {
    if (property && property.contactStaff && property.contactStaff[0]) {
      const staff = property.contactStaff[0];
      return {
        name: `${staff.firstName} ${staff.lastName}`,
        email: staff.email,
        photo: staff.photo ? staff.photo.thumb_360 : null,
        telephone: staff.phoneNumbers && staff.phoneNumbers.find(p => p.typeCode === 'D')?.number || '',
        mobile: staff.phoneNumbers && staff.phoneNumbers.find(p => p.typeCode === 'M')?.number || '',
      };
    }
    return {};
  }, [property]);

  const inspectionBadge = useMemo(() => {
    if (upcomingInspections.length > 0) {
      return (
        <span style={{
          position: 'absolute',
          top: 0,
          left: 0,
          background: '#b53428',
          color: '#fff',
          padding: '5px 10px',
          fontSize: '12px',
          fontWeight: 'bold',
          zIndex: 1
        }}>
          Open {new Date(upcomingInspections[0].start).toLocaleString('en-US', { weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true })}
        </span>
      );
    }
    return (
      <span style={{
        position: 'absolute',
        top: 0,
        left: 0,
        background: '#b53428',
        color: '#fff',
        padding: '5px 10px',
        fontSize: '12px',
        fontWeight: 'bold',
        zIndex: 1
      }}>
        Contact for Inspection
      </span>
    );
  }, [upcomingInspections]);

  return (
    <div className="col-lg-4 col-sm-6 col-12" key={`property-card-sale-${index}`}>
      <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
        <div className="product-img" style={{ position: 'relative' }}>
          {inspectionBadge}
          <Link
            to={{
              pathname: "/property-details",
              search: `?id=${property.id}&lifeid=${property.saleLifeId}&type=sale`,
              state: { property: property },
            }}
          >
            <img 
              src={photos.length > 0 ? photos[0].thumbnails.thumb_1024 : publicUrl + 'assets/img/product-3/1.jpg'}
              alt="#"
              style={{
                height: '280px',
                width: '100%',
                objectFit: 'cover'
              }}
            />
          </Link>
          <div className="real-estate-agent">
            <div className="agent-img">
              <img src={contact.photo} alt="#" />
            </div>
          </div>
        </div>
        <div className="product-info">
          <div className="product-badge">
            <ul>
              <li className="sale-badg">For Sale</li>
            </ul>
          </div>
          <h2 className="product-title go-top">
            <Link
              to={{
                pathname: "/property-details",
                search: `?id=${property.id}&lifeid=${property.saleLifeId}&type=sale`,
                state: { property: property },
              }}
            >
              {displayAddress}
            </Link>
          </h2>
          <div className="product-img-location">
            <ul>
              <li className="go-top">
                <i className="flaticon-pin"/> {displayAddress}
              </li>
            </ul>
          </div>
          <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
            <li style={{ display: 'flex', alignItems: 'flex-start', lineHeight: '1.5' }}>
              <i className="flaticon-bed" style={{ marginRight: '8px', fontSize: '1.2em' }}></i>
              <span>{bed} Bedrooms</span>
            </li>
            <li style={{ display: 'flex', alignItems: 'flex-start', lineHeight: '1.5' }}>
              <i className="flaticon-clean" style={{ marginRight: '8px', fontSize: '1.2em' }}></i>
              <span>{bath} Bathrooms</span>
            </li>
            <li style={{ display: 'flex', alignItems: 'flex-start', lineHeight: '1.5' }}>
              <i className="flaticon-car" style={{ marginRight: '8px', fontSize: '1.2em' }}></i>
              <span>{carSpaces || carports || garages || openSpaces} Carpark</span>
            </li>
          </ul>
          <div className="product-hover-action">
            {/* ... existing action buttons */}
          </div>
        </div>
        <div className="product-info-bottom">
          <div className="product-price">
            <span>{displayPrice}</span>
          </div>
        </div>
      </div>
    </div>
  );
});

export const PropertyCardSold = ({ property, index }) => {
  if (!property) {
    return <PropertySkeletonCardItem index={index}/>
  }

  let publicUrl = process.env.PUBLIC_URL + '/'

  const {
    photos, displayAddress, displayPrice,
    bed, bath, floorArea,
    carSpaces, carports, garages, openSpaces,
    contactStaff } = property;

  const contact = property && property.contactStaff && property.contactStaff[0] ? {
    name: `${property.contactStaff[0].firstName} ${property.contactStaff[0].lastName}`,
    email: property.contactStaff[0].email,
    photo: property.contactStaff[0].photo ? property.contactStaff[0].photo.thumb_360 : null,
    telephone: property.contactStaff[0].phoneNumbers && property.contactStaff[0].phoneNumbers.find(p => p.typeCode === 'D')?.number || '',
    mobile: property.contactStaff[0].phoneNumbers && property.contactStaff[0].phoneNumbers.find(p => p.typeCode === 'M')?.number || '',
  } : {};

  return (
    <div className="col-lg-4 col-sm-6 col-12" key={`property-card-sale-${index}`}>
      <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
        <div className="product-img">
          <Link
            to={{
              pathname: "/property-details",
              search: `?id=${property.id}&type=sold`,  // query parameters can be set here
              state: {
                property: property,
              }
            }}
          >
            <img src={photos.length > 0 ? photos[0].thumbnails.thumb_1024 : publicUrl + 'assets/img/product-3/1.jpg'}
                 alt="#"
                 style={{
                   height: '280px',
                   width: '100%',  // This sets the width to fill the container
                   objectFit: 'cover'  // This scales the image to cover the space
                 }}
            />
          </Link>
          <div className="real-estate-agent">
            <div className="agent-img">
              <img src={contact.photo} alt="#"/>
            </div>
          </div>
        </div>
        <div className="product-info">
          <div className="product-badge">
            <ul>
              <li className="sale-badg">Sold</li>
            </ul>
          </div>
          <h2 className="product-title go-top">
            <Link
              to={{
                pathname: "/property-details",
                search: `?id=${property.id}&type=sold`,  // query parameters can be set here
                state: {
                  property: property,
                }
              }}
            >
              {displayAddress}
            </Link>
          </h2>
          <div className="product-img-location">
            <ul>
              <li className="go-top">
                <i className="flaticon-pin"/> {displayAddress}
              </li>
            </ul>
          </div>
          <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
            <li style={{ display: 'flex', alignItems: 'flex-start', lineHeight: '1.5' }}>
              <i className="flaticon-bed" style={{ marginRight: '8px', fontSize: '1.2em' }}></i>
              <span>{bed} Bedrooms</span>
            </li>
            <li style={{ display: 'flex', alignItems: 'flex-start', lineHeight: '1.5' }}>
              <i className="flaticon-clean" style={{ marginRight: '8px', fontSize: '1.2em' }}></i>
              <span>{bath} Bathrooms</span>
            </li>
            <li style={{ display: 'flex', alignItems: 'flex-start', lineHeight: '1.5' }}>
              <i className="flaticon-car" style={{ marginRight: '8px', fontSize: '1.2em' }}></i>
              <span>{carSpaces || carports || garages || openSpaces} Carpark</span>
            </li>
          </ul>
          <div className="product-hover-action">
            {/* ... existing action buttons */}
          </div>
        </div>
        <div className="product-info-bottom">
          <div className="product-price">
            <span>Contact Agent</span>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * @param property {Property}
 * @param index {number}
 * @returns {Element}
 * @constructor
 */
export function PropertyListItemSale({ property, index }) {
  if (!property) {
    // Render some kind of loading or empty state here
    return <PropertySkeletonListItem index={index}/>
  }

  let publicUrl = process.env.PUBLIC_URL + '/'

  console.log('PropertyListItemSale >> property', property);

  const contact = property && property.contactStaff && property.contactStaff[0] ? {
    name: `${property.contactStaff[0].firstName} ${property.contactStaff[0].lastName}`,
    email: property.contactStaff[0].email,
    photo: property.contactStaff[0].photo ? property.contactStaff[0].photo.thumb_360 : null,
    telephone: property.contactStaff[0].phoneNumbers && property.contactStaff[0].phoneNumbers.find(p => p.typeCode === 'D')?.number || '',
    mobile: property.contactStaff[0].phoneNumbers && property.contactStaff[0].phoneNumbers.find(p => p.typeCode === 'M')?.number || '',
  } : {};

  return (
    <div className="col-lg-12" key={`property-list-item-sale-${index}`}>
      <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
        <div className="product-img">
          <Link
            to={{
              pathname: "/property-details?id=" + property.id + "&type=sale",
              state: {
                property: property,
              }
            }}
          >
            <img
              src={property.photos.length > 0 ? property.photos[0].thumbnails.thumb_1024 : publicUrl + 'assets/img/product-3/1.jpg'}
              alt="#"
              style={{
                height: '280px',
                width: '366px',  // This sets the width to fill the container
                objectFit: 'cover'  // This scales the image to cover the space
              }}
            />
          </Link>
        </div>
        <div className="product-info">
          <div className="product-badge-price">
            <div className="product-badge">
              <ul>
                <li className="sale-badg">For Sale</li>
              </ul>
            </div>
            <div className="product-price">
              <span>{property ? (property.displayPrice ? property.displayPrice : 'Contact Agent') : ''}</span>
            </div>
          </div>
          <h2 className="product-title go-top">
            <Link to="/property-details">
              {property ? property.displayAddress : ''}
            </Link>
          </h2>
          <div className="product-img-location">
            <ul>
              <li className="go-top">
                <Link to="/contact"><i className="flaticon-pin"/> {property ? property.displayAddress : ''}</Link>
              </li>
            </ul>
          </div>
          <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
            <li><span>{property ? property.bed : ''} </span> Bedrooms</li>
            <li><span>{property ? property.bath : ''} </span> Bathrooms</li>
            <li>
              <span>{property ? (property.floorArea && property.floorArea.value > 0.0 ? property.floorArea.value : '-') : ''} </span> Square
              meters
            </li>
          </ul>
        </div>
        <div className="product-info-bottom">
          {/*<div className="real-estate-agent">*/}
          {/*  <div className="agent-hoyee-img">*/}
          {/*    <img*/}
          {/*      src={'/assets/img/favicon.jpg'}*/}
          {/*      alt="#"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="product-hover-action">*/}
          {/*  <ul>*/}
          {/*    <li>*/}
          {/*      <a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">*/}
          {/*        <i className="flaticon-expand" />*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li>*/}
          {/*      <a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">*/}
          {/*        <i className="flaticon-heart-1" />*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li>*/}
          {/*      <span className="go-top">*/}
          {/*        <Link to="/property-details" title="Product Details">*/}
          {/*          <i className="flaticon-add" />*/}
          {/*        </Link>*/}
          {/*      </span>*/}
          {/*    </li>*/}
          {/*  </ul>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
}

// PropertyCardLease Component
export const PropertyCardLease = ({ property, index }) => {
  if (!property) {
    return <PropertySkeletonCardItem index={index} />;
  }

  const publicUrl = process.env.PUBLIC_URL + '/';

  const contactData = extractLeasingPropertyContact(property);
  const contact = {
    name: contactData.name || 'Chloe Zang',
    telephone: contactData.telephones.default,
    mobile: contactData.telephones.mobile || '',
    email: contactData.email,
    photo: property.images[0].img[0] ? property.images[0].img[0]['$'].url : null,
  };

  const inspectionTime = property.inspectionTimes[0] ? property.inspectionTimes[0].inspection : '';

  const formatInspectionDate = (dateString) => {
    const [datePart, startTime, to, endTime] = dateString.split(' ');
    const [day, month, year] = datePart.split('-');

    const date = new Date(`${year}-${month}-${day}`);
    
    return {
      day: date.toLocaleString('en-US', { weekday: 'long' }),
      date: date.getDate(),
      month: date.toLocaleString('en-US', { month: 'long' }),
      time: `${startTime} to ${endTime}`
    };
  };

  const getUpcomingInspections = () => {
    if (property && property.inspectionTimes && property.inspectionTimes[0]) {
      console.log('getUpcomingInspections >> property.inspectionTimes', property.inspectionTimes);
      return property.inspectionTimes[0].inspection
      .filter(inspection => {
        const formattedDate = formatInspectionDate(inspection);
        return formattedDate.date >= new Date().getDate();
      })
      .map(inspection => formatInspectionDate(inspection));
    }
    return [];
  };

  return (
    <div className="col-lg-4 col-sm-6 col-12" key={`property-card-sale-${index}`}>
      <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
        <div className="product-img" style={{ position: 'relative' }}>
          {getUpcomingInspections().length > 0 &&  (
            <span style={{
              position: 'absolute',
              top: 0,
              right: 0,
              background: '#b53428',
              color: '#ffffff', // Adding white font color to contrast with dark background
              padding: '0.571428571rem 1.714285713rem',
              fontSize: '0.8571428571rem',
              letterSpacing: '1px',
              textTransform: 'uppercase',
              zIndex: 1
            }}>
              {`Open ${getUpcomingInspections()[0].day}`}
            </span>
          )}
          <Link
            to={{
              pathname: "/property-details",
              search: `?id=${property.uniqueID[0]}&type=rent`,  // query parameters can be set here
              state: {
                property: property,
              }
            }}
          >
            <img
              src={
                property.images[0].img[0]
                  ? imageProxyUrl(property.images[0].img[0]['$'].url)
                  : publicUrl + 'assets/img/product-3/1.jpg'
              }
              alt="#"
              style={{
                height: '280px',
                width: '100%',  // This sets the width to fill the container
                objectFit: 'cover'  // This scales the image to cover the space
              }}
            />
          </Link>
          {/*<div className="real-estate-agent">*/}
          {/*  <div className="agent-hoyee-img">*/}
          {/*    <img*/}
          {/*      src={'/assets/img/favicon.jpg'}*/}
          {/*      alt="#"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        <div className="product-info">
          <div className="product-badge">
            <ul>
              <li className="sale-badg">For Rent</li>
            </ul>
          </div>
          <h2 className="product-title go-top">
            <Link
              to={{
                pathname: "/property-details",
                search: `?id=${property.uniqueID[0]}&type=rent`,  // query parameters can be set here
                state: {
                  property: property,
                }
              }}
            >
              {extractLeasingPropertyAddress(property)}
            </Link>
          </h2>
          <div className="product-img-location">
            <ul>
              <li>
                {/*<Link to="/contact"><i className="flaticon-pin"/>*/}
                  {extractLeasingPropertyAddress(property)}
                {/*</Link>*/}
              </li>
            </ul>
          </div>
          <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <li style={{ display: 'flex', alignItems: 'flex-start', lineHeight: '1.5', marginRight: '15px' }}>
              <i className="flaticon-bed" style={{ marginRight: '8px', fontSize: '1.2em' }}></i>
              <span>{property.features[0].bedrooms[0]} Bedrooms</span>
            </li>
            <li style={{ display: 'flex', alignItems: 'flex-start', lineHeight: '1.5', marginRight: '15px' }}>
              <i className="flaticon-clean" style={{ marginRight: '8px', fontSize: '1.2em' }}></i>
              <span>{property.features[0].bathrooms[0]} Bathrooms</span>
            </li>
            <li style={{ display: 'flex', alignItems: 'flex-start', lineHeight: '1.5', marginRight: '15px' }}>
              <i className="flaticon-car" style={{ marginRight: '8px', fontSize: '1.2em' }}></i>
              <span>{extractLeasingPropertyCarSpaces(property)} Carpark</span>
            </li>
          </ul>
          <div className="product-hover-action">
            {/* ... existing action buttons */}
          </div>
        </div>
        <div className="product-info-bottom">
          <div className="product-price">
            <span>{extractLeasingPropertyRent(property)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

// PropertyListItemLease Component
export const PropertyListItemLease = ({ property, index }) => {
  if (!property) {
    return <PropertySkeletonListItem index={index} />;
  }

  const publicUrl = process.env.PUBLIC_URL + '/';

  console.log('PropertyListItemLease >> property', property);

  const contactData = extractLeasingPropertyContact(property);
  const contact = {
    name: contactData.name || 'Chloe Zang',
    telephone: contactData.telephones.default,
    mobile: contactData.telephones.mobile || '',
    email: contactData.email,
    photo: property.images[0].img[0] ? property.images[0].img[0]['$'].url : null,
  };

  return (
    <div className="col-lg-12" key={`property-list-item-sale-${index}`}>
      <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
        <div className="product-img">
          <Link to="/property-details">
            <img
              src={
                property.images[0].img[1]
                  ? imageProxyUrl(property.images[0].img[1]['$'].url)
                  : publicUrl + 'assets/img/product-3/1.jpg'
              }
              alt="#"
              style={{
                height: '280px',
                width: '366px',  // This sets the width to fill the container
                objectFit: 'cover'  // This scales the image to cover the space
              }}
            />
          </Link>
        </div>
        <div className="product-info">
          <div className="product-badge-price">
            <div className="product-badge">
              <ul>
                <li className="sale-badg">For Rent</li>
              </ul>
            </div>
            <div className="product-price">
              <span>{extractLeasingPropertyRent(property)}</span>
            </div>
          </div>
          <h2 className="product-title go-top">
            <Link to="/property-details">
              {extractLeasingPropertyAddress(property)}
            </Link>
          </h2>
          <div className="product-img-location">
            <ul>
              <li className="go-top">
                <Link to="/contact"><i className="flaticon-pin"/>
                  {extractLeasingPropertyAddress(property)}
                </Link>
              </li>
            </ul>
          </div>
          <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
            <li style={{ display: 'flex', alignItems: 'flex-start', lineHeight: '1.5' }}>
              <i className="flaticon-bed" style={{ marginRight: '8px', fontSize: '1.2em' }}></i>
              <span>{property.features[0].bedrooms[0]} Bedrooms</span>
            </li>
            <li style={{ display: 'flex', alignItems: 'flex-start', lineHeight: '1.5' }}>
              <i className="flaticon-clean" style={{ marginRight: '8px', fontSize: '1.2em' }}></i>
              <span>{property.features[0].bathrooms[0]} Bathrooms</span>
            </li>
            <li style={{ display: 'flex', alignItems: 'flex-start', lineHeight: '1.5' }}>
              <i className="flaticon-car" style={{ marginRight: '8px', fontSize: '1.2em' }}></i>
              <span>{extractLeasingPropertyCarSpaces(property)} Carpark</span>
            </li>
          </ul>
        </div>
        <div className="product-info-bottom">
          <div className="real-estate-agent">
            <div className="agent-img">
              <img src={contact.photo} alt="#"/>
            </div>
            <div className="agent-brief go-top">
              <h6><Link to="/team-details">{contact.name}</Link></h6>
              <small>HOYEE</small>
            </div>
          </div>
          {/*<div className="product-hover-action">*/}
          {/*  <ul>*/}
          {/*    <li>*/}
          {/*      <a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">*/}
          {/*        <i className="flaticon-expand" />*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li>*/}
          {/*      <a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">*/}
          {/*        <i className="flaticon-heart-1" />*/}
          {/*      </a>*/}
          {/*    </li>*/}
          {/*    <li>*/}
          {/*      <span className="go-top">*/}
          {/*        <Link to="/property-details" title="Product Details">*/}
          {/*          <i className="flaticon-add" />*/}
          {/*        </Link>*/}
          {/*      </span>*/}
          {/*    </li>*/}
          {/*  </ul>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};



