import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import AppraisalForm from "./section-components/appraisal-form";
import { Helmet } from 'react-helmet';
import './appraisal.css';

const AppraisalRental = () => {
  let publicUrl = process.env.PUBLIC_URL + '/'
  console.log('publicUrl:', publicUrl)

  return <div>
    <Helmet>
      <title>Hoyee Property Group | Free Rental Appraisal | Australia</title>
    </Helmet>
    <Navbar />
    <PageHeader headertitle="" subheader="" />
    <div className="ltn__blog-details-wrap" style={{border: 'none'}}>
      <div className="ltn__page-details-inner ltn__blog-details-inner">
        <p>
          Discover how much more your property is really worth with HOYEE’s leading team of property valuation experts.
        </p>
        <p>
          We provide 100% obligation & cost FREE rental appraisals for landlords – including those with existing properties or looking to invest in new ones.
        </p>
        <p>
          Our team won’t let you be cut short of what you deserve. We have among the most talented and experienced property valuation experts in Melbourne – all ready to provide you with extremely accurate rental appraisals that are based on years of specialist experience in the rental property market. We assure you that you’ll receive the most reliable and precise rental valuations no matter where the location or type of property you own.
        </p>

        <h1 className="ltn__blog-title" style={{textAlign: "center"}}>
          Why get a rental appraisal for your property?
        </h1>
        <p>
          The answer is simple: to ensure you get the full picture in terms of what your property’s expected rent will be, which ensures that you’re getting the most value out of your investment property as possible.
        </p>
        <p>
          This is why more and more investors like yourself are carrying out rental appraisals for properties all over Melbourne – whether for units, apartments, holiday homes, or any other rental property type.
        </p>

        <h1 className="ltn__blog-title" style={{textAlign: "center"}}>
          What’s included in our rental appraisal services?
        </h1>
        <p>
          We provide you with accurate rental appraisals and valuations on your rental properties by taking into account things such as:
        </p>
        <ul>
          <li>
            The comparable lettings in the area
          </li>
          <li>
            Current available rental properties
          </li>
          <li>
            Previous property sales data
          </li>
          <li>
            The median house prices in your property area
          </li>
          <li>
            Demographics of the residents within the suburb of focus (and more)
          </li>
        </ul>

        <p>
          But, that’s not all. Our rental appraisals are also highly sought after for other reasons such as:
        </p>
        <ul>
          <li>
            To assist with Bank Finance
          </li>
          <li>
            To check current returns
          </li>
          <li>
            Lease renewals
          </li>
          <li>
            Rent reviews
          </li>
          <li>
            Conduct a full assessment of a new investment property
          </li>
        </ul>

        <h1 className="ltn__blog-title" style={{textAlign: "center"}}>
          Take Advantage of Our 100% OBLIGATION & COST FREE Rental Appraisals at Hoyee Property Group
        </h1>
        <p>
          If you’re after rental property appraisal service that are second to none, look no further than the leading property management team at Hoyee Property Group.
        </p>
        <p>
          Rental Appraisals are commonly sought by investors for the purpose of establishing the likely expected leasing price of a property. Such appraisal takes into account comparable lettings in the area and current available properties. Rental appraisals are also sought for the following reasons:
        </p>
        <ul>
          <li>
            To assist with Bank Finance
          </li>
          <li>
            To check current returns
          </li>
          <li>
            Lease renewals
          </li>
          <li>
            Rent reviews
          </li>
          <li>
            Assessment of a new investment property
          </li>
        </ul>
        <p>
          Whether you’d like to know what your home is likely to rent for or its worth, our team will provide a quick, reliable and honest appraisal of your property.
        </p>
        <h4>
          Fill out your details below or
          call <a href="tel:0387433342">03 8743 3342</a> to speak to a consultant, or
          email <a href="mailto:info@hoyee.com.au" style={{textDecoration: 'underline'}}>info@hoyee.com.au</a>
        </h4>
      </div>
    </div>
    <div className={'appraisal-bottom-box'}>
      <div className={'appraisal-bottom-box-left-bg'} style={{
        backgroundImage: `linear-gradient(rgba(24, 24, 24, 0.8), rgba(24, 24, 24, 1)), url(${publicUrl}assets/img/others/17.webp)`,
        backgroundSize: 'cover',
      }}>
        <h1 style={{fontFamily: 'poppins-semibold,poppins,sans-serif', color: 'white', fontSize: '56px', lineHeight: '1em', fontWeight:'600'}}>Get a free rental appraisal</h1>
        <hr style={{borderTop: '3px dashed white', height:'3px', opacity: 1, width: '10%'}} />
        <p style={{color: 'white', fontSize: '20px'}}>Discover more about our quality services.</p>
        {(
          <svg preserveAspectRatio="xMidYMid meet" data-bbox="19.999 25 160.002 150" viewBox="19.999 25 160.002 150" height="100" width="100" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true">
            <g fill="#FFFFFF">
              <path d="M135.555 61.102a3.812 3.812 0 0 1-3.81-3.814V38.983a3.811 3.811 0 1 1 7.62 0v18.305a3.812 3.812 0 0 1-3.81 3.814z"></path>
              <path d="M155.873 77.373a3.812 3.812 0 0 1-3.81-3.814V38.983a3.811 3.811 0 1 1 7.62 0v34.576a3.812 3.812 0 0 1-3.81 3.814z"></path>
              <path d="M155.873 42.796h-20.317c-2.104 0-3.81-1.707-3.81-3.814s1.705-3.814 3.81-3.814h20.317c2.104 0 3.81 1.707 3.81 3.814s-1.706 3.814-3.81 3.814z"></path>
              <path d="M23.81 175a3.812 3.812 0 0 1-3.81-3.814V89.83a3.811 3.811 0 1 1 7.62 0v81.356A3.812 3.812 0 0 1 23.81 175z"></path>
              <path d="M176.19 175a3.812 3.812 0 0 1-3.81-3.814V89.83c0-2.106 1.705-3.814 3.81-3.814S180 87.724 180 89.83v81.356a3.812 3.812 0 0 1-3.81 3.814z"></path>
              <path d="M176.19 175H23.81c-2.104 0-3.81-1.707-3.81-3.814s1.705-3.814 3.81-3.814h152.38c2.104 0 3.81 1.707 3.81 3.814S178.294 175 176.19 175z"></path>
              <path d="M23.812 93.644a3.8 3.8 0 0 1-2.977-1.431 3.817 3.817 0 0 1 .595-5.361l76.19-61.017a3.809 3.809 0 0 1 5.355.596 3.817 3.817 0 0 1-.595 5.361L26.19 92.809a3.795 3.795 0 0 1-2.378.835z"></path>
              <path d="M135.553 61.102a3.793 3.793 0 0 1-2.377-.835L97.62 31.792a3.817 3.817 0 0 1-.595-5.361 3.81 3.81 0 0 1 5.355-.596l35.555 28.475a3.817 3.817 0 0 1 .595 5.361 3.8 3.8 0 0 1-2.977 1.431z"></path>
              <path d="M176.188 93.644a3.79 3.79 0 0 1-2.377-.835l-20.317-16.271a3.817 3.817 0 0 1-.595-5.361 3.808 3.808 0 0 1 5.355-.596l20.317 16.271a3.817 3.817 0 0 1 .595 5.361 3.807 3.807 0 0 1-2.978 1.431z"></path>
              <path d="M74.603 144.491c-13.304 0-24.127-10.835-24.127-24.153S61.3 96.186 74.603 96.186c7.827 0 15.174 3.844 19.672 10.167 6.793.002 28.802.003 41.281.003a3.81 3.81 0 0 1 2.694 1.117l10.159 10.17a3.816 3.816 0 0 1 0 5.393l-10.159 10.17a3.81 3.81 0 0 1-2.694 1.117H94.275c-4.496 6.322-11.843 10.168-19.672 10.168zm0-40.678c-9.103 0-16.508 7.414-16.508 16.525s7.405 16.525 16.508 16.525c5.875 0 11.355-3.167 14.299-8.265a3.809 3.809 0 0 1 3.298-1.905h41.778l6.349-6.356-6.349-6.356-39.915-.003c-2.243 0-4.372-.001-5.31-2.159-2.98-4.945-8.374-8.006-14.15-8.006z"></path>
              <path d="M105.079 134.322a3.812 3.812 0 0 1-3.81-3.814v-5.085a3.812 3.812 0 0 1 3.81-3.814 3.812 3.812 0 0 1 3.81 3.814v5.085a3.811 3.811 0 0 1-3.81 3.814z"></path>
              <path d="M120.317 134.322a3.812 3.812 0 0 1-3.81-3.814v-5.085a3.812 3.812 0 0 1 3.81-3.814 3.812 3.812 0 0 1 3.81 3.814v5.085a3.811 3.811 0 0 1-3.81 3.814z"></path>
              <path d="M74.654 124.152h-.102c-2.104 0-3.81-1.707-3.81-3.814s1.705-3.814 3.81-3.814h.102c2.104 0 3.81 1.707 3.81 3.814s-1.706 3.814-3.81 3.814z"></path>
            </g>
          </svg>
        )}
      </div>
      <AppraisalForm type={'rent'}/>
    </div>
    <Footer />
  </div>
}

export default AppraisalRental
