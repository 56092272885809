import React, { Component } from 'react';

class Social extends Component {

  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'

    return (
      <div className="ltn__social-media">
        <ul>
          <li>
            <a href="https://facebook.com/Hoyee-International-108206555314774/" title="Facebook" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f"/>
            </a>
          </li>
          {/*<li>
            <a href="https://www.twitter.com/tuna-theme" title="Twitter" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter" />
            </a>
          </li>*/}
          <li>
            <a href="https://www.instagram.com/hoyeepropertygroup" title="Instagram" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"/>
            </a>
          </li>
          <li>
            <a href="https://linkedin.com/company/hoyeeinternational" title="LinkedIn" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin"/>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Social;
