import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';

class SearchFormOnHomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchType: 'House',
      searchStatus: 'Buy',
      searchQuery: '',
    };
  }

  handleSearchTypeChange = (event) => {
    const $ = window.$;
    const selectedValue = $(event.target)?.val();
    // console.log('DEBUG >> handleSearchTypeChange >> selectedValue', selectedValue);
    this.setState({ searchType: selectedValue });
  };

  handleSearchStatusChange = (event) => {
    const $ = window.$;
    const selectedValue = $(event.target)?.val();
    // console.log('DEBUG >> handleSearchStatusChange >> selectedValue', selectedValue);
    // console.log('DEBUG >> handleSearchStatusChange >> event.target.value', event.target?.value);
    this.setState({ searchStatus: selectedValue });
  };

  handleSearchQueryChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { searchType, searchStatus, searchQuery } = this.state;
    let path = `/property-grid/${searchStatus.toLowerCase()}?type=${encodeURIComponent(searchType.toLowerCase())}&query=${encodeURIComponent(searchQuery.toLowerCase())}`;
    this.props.history.push(path);
  };

  render() {

    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'

    return <div className="ltn__car-dealer-form-area search-firm-margin-top mt-128 pb-115---">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__car-dealer-form-tab">
              <div className="ltn__tab-menu  text-uppercase d-none">
                <div className="nav">
                  <a className="active show" data-bs-toggle="tab" href="#ltn__form_tab_1_1"><i className="fas fa-car"/>Find
                    A Car</a>
                  <a data-bs-toggle="tab" href="#ltn__form_tab_1_2"><i className="far fa-user"/>Get a Dealer</a>
                </div>
              </div>
              <div className="tab-content search-firm-bg box-shadow-1 position-relative pb-10">
                <div className="tab-pane fade active show" id="ltn__form_tab_1_1">
                  <div className="car-dealer-form-inner">
                    <form onSubmit={this.handleSubmit} className="ltn__car-dealer-form-box row">
                      <div
                        className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-car---- col-lg-2 col-md-3">
                        <select
                          className="nice-select"
                          id="home-search-type"
                          // Work on hacked nice-select plugin. Find note in `quater/public/assets/js/plugins.js`, Nice Select section.
                          onClick={this.handleSearchTypeChange}
                        >
                          <option>House</option>
                          <option>Apartment</option>
                          <option>Townhouse</option>
                          <option>Unit</option>
                          <option>Land</option>
                          <option>Commercial</option>
                          <option>Other</option>
                        </select>
                      </div>
                      <div
                        className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-meter---- col-lg-2 col-md-3">
                        <select
                          className="nice-select"
                          id="home-search-status"
                          // Work on hacked nice-select plugin. Find note in `quater/public/assets/js/plugins.js`, Nice Select section.
                          onClick={this.handleSearchStatusChange}
                        >
                          <option>Buy</option>
                          <option>Rent</option>
                          <option>Sold</option>
                        </select>
                      </div>
                      <div className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-calendar---- col-lg-6 col-md-6">
                        <input
                          type="text"
                          className="form-control" // Updated class to 'form-control' for standard text input
                          id="home-search-address"
                          placeholder="Search for properties, suburbs, or keywords…"
                          value={this.state.searchQuery} // Bind the input value to the component state
                          onChange={this.handleSearchQueryChange} // Call the updated handleSearchQueryChange on input change
                          style={{maxHeight: '60px'}}
                        />
                      </div>
                      <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-2 col-md-3">
                        <div className="btn-wrapper text-center mt-0 go-top">
                          <button type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase">Find</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="tab-pane fade" id="ltn__form_tab_1_2">
                  <div className="car-dealer-form-inner">
                    <form onSubmit={this.handleSubmit} className="ltn__car-dealer-form-box row">
                      <div
                        className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-car---- col-lg-3 col-md-6">
                        <select className="nice-select">
                          <option>Choose Area</option>
                          <option>chicago</option>
                          <option>London</option>
                          <option>Los Angeles</option>
                          <option>New York</option>
                          <option>New Jersey</option>
                        </select>
                      </div>
                      <div
                        className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-meter---- col-lg-3 col-md-6">
                        <select className="nice-select">
                          <option>Property Status</option>
                          <option>Open house</option>
                          <option>Rent</option>
                          <option>Sale</option>
                          <option>Sold</option>
                        </select>
                      </div>
                      <div
                        className="ltn__car-dealer-form-item ltn__custom-icon---- ltn__icon-calendar---- col-lg-3 col-md-6">
                        <select className="nice-select">
                          <option>Property Type</option>
                          <option>Apartment</option>
                          <option>Co-op</option>
                          <option>Condo</option>
                          <option>Single Family Home</option>
                        </select>
                      </div>
                      <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
                        <div className="btn-wrapper text-center mt-0 go-top">
                          {/* <button type="submit" class="btn theme-btn-1 btn-effect-1 text-uppercase">Search Inventory</button> */}
                          <Link to="/go-top" className="btn theme-btn-1 btn-effect-1 text-uppercase">Search
                            Properties</Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default withRouter(SearchFormOnHomePage);
