import axios from 'axios';

const pending = [];
export const cancelPending = (pendingList,config) => {
  pendingList.forEach((item, index) => {
    if (config) {
      if (item.u === config.url) {
        item.f() // 取消请求
        pendingList.splice(index, 1) // 移除当前请求记录
      }
    } else {
      item.f() // 取消请求
      pendingList.splice(index, 1) // 移除当前请求记录
    }
  })
}

export const BASE_URL = process.env.REACT_APP_DEV ? 'http://localhost:5500' : '/';

// 封闭请求
const instance = axios.create({
  // baseURL: process.env.REACT_APP_DEV ? 'https://hoyee-website.vercel.app' : '/',
  baseURL: BASE_URL,
  timeout: 30000,
});

[instance].forEach((instance) => {

  // 添加响应拦截器
  instance.interceptors.response.use(
    response => {
      cancelPending(pending, response.config)
      /**
        * code为非0是抛错 可结合自己业务进行修改
        */
      const res = response.data || {};
      const { RetCode, Data, Message } = res;
      // token 无效 或者 token 过期

      if (RetCode && RetCode !== 0) {
        let errorMessage;
        switch (RetCode) {
          case -1:
            errorMessage = 'ServerError->' + Message;
            break;
          default:
            errorMessage = RetCode+ ':' + Message;
            break;
        }
        if (response?.config?.params?.TrackSN) {
          errorMessage = errorMessage + '(' + response.config.params.TrackSN + ')';
        }
        const errorObject = { code: RetCode, message: errorMessage };
        console.error(errorObject);
        return Promise.reject(errorObject)
      } else if(Data){
        return Data
      } else {
        return response.data
      }
    },
    error => {
      if (!error.response) {
        return Promise.reject(error)
      }
      if (error.response?.config?.method === 'get' && (error.response.status === 404 || error.response.status === 415)) {
        return Promise.reject(error)
      } else {
        return Promise.reject(error)
      }
    }
  )

});
export default instance;
