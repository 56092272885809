import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar';
import ShogGrid from './shop-components/shop-grid-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import SearchFormOnRentListPage from './section-components/search-from-on-rent-list-page';

/**
 * @typedef {Object} ShopGridProps
 * @property {'sell' | 'sold' | 'buy' | 'rent'} type
 * @property {Object} searchOptions
 * @property {string} searchOptions.propertyType
 * @property {string} searchOptions.bedrooms
 * @property {string} searchOptions.bathrooms
 * @property {string} searchOptions.carSpaces
 * @property {string} searchOptions.maxPrice
 * @property {Function} onFetchComplte
 */

/**
 * Note: This component is used only for rent properties. The reason to
 * create a new component is that to generate a new instance of filter form.
 * The filter form is based on jquery plugin `nice-select`. The plugin keeps status and fixed options
 * if its component remains in one page.
 *
 * We can reset options to default value, but it requests more works.
 * Check the commented code `componentDidUpdate` in `SearchFormOnListPage.js`.
 * It reset select propertyType to be the default option 'All'.
 *
 * So that we create a new page(component) to avoid that work load.
 *
 * @param {ShopGridProps} props
 */
const ShopGridRent = (props) => {
  console.log('ShopGridRent >>> props.type', props.type);
  const keyword = props.type === 'sell' ? 'Sell' : props.type === 'sold' ? 'Sold' : props.type === 'buy' ? 'Buy' : props.type === 'rent' ? 'Rent' : 'Properties';

  const [searchOptions, setSearchOptions] = React.useState({
    propertyType: 'All',
    bedrooms: 'Bed',
    bathrooms: 'Bath',
    carSpaces: 'Car',
    maxPrice: 'Max Price',
  });

  const handleSearchOptionsChange = (newOptions) => {
    setSearchOptions({...searchOptions, ...newOptions});
  };

  const [fetchComplete, setFetchComplete] = useState(false);
  const handleFetchComplete = () => {
    setFetchComplete(true);
  }

  const [searchType, setSearchType] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    setSearchType(params.get('type'));
    setSearchQuery(params.get('query'));
  }, [location]);

  useEffect(() => {
    console.log('ShopGridRent >>>');
  }, []);


  return <div>
    <Helmet>
      <title>Hoyee Property Group | {keyword} | Australia</title>
    </Helmet>
    <Navbar />
    {/*<PageHeader headertitle="Properties" />*/}
    <SearchFormOnRentListPage
      searchType={searchType}
      searchOptions={searchOptions}
      onSearchOptionsChange={handleSearchOptionsChange}
      fetchComplete={fetchComplete}
    />
    <ShogGrid
      type={props.type}
      searchOptions={searchOptions}
      onFetchComplte={handleFetchComplete}
    />
    <CallToActionV1 />
    <Footer />
  </div>
}

export default ShopGridRent

