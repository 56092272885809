import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Property } from '../../types/type';
import { sendEmail } from '../../api';
import AlertMessage from '../alert-message';
import './shop-details-sale.css';

/**
 * @typedef {Object} ShopDetailsProps
 * @property {string} id
 * @property {Property} property
 * @property {string} paddingTop
 * @property {string} type
 * @property {Object} inspections
 */

/**
 * @param {ShopDetailsProps} props
 */
const ShopDetailsSale = (props) => {
  let { property, type, inspections } = props;
  property = property || {};
  let publicUrl = process.env.PUBLIC_URL + '/'

  const getCategory = () => {
    if (type === 'sale') {
      return 'For Sale';
    } else if (type === 'rent') {
      return 'For Rent';
    } else if (type === 'sold') {
      return 'Sold';
    } else {
      return '';
    }
  }

  const getHTML = (text) => {
    const html = text.split('\n\n').map(paragraph =>
      `<p>${paragraph.replace(/\n/g, '<br>')}</p>`).join('');

    return html;
  }

  const getFloorPlans = () => {
    if (property && property.photos && property.photos.length > 0) {
      const plans = property.photos.filter(p => p.type === 'Floorplan');
      console.log('getFloorPlans >> plans', plans);
      return plans;
    }

    return [];
  }
  const floorPlans = getFloorPlans();

  const latitude = property.geolocation && property.geolocation.latitude || undefined;
  const longitude = property.geolocation && property.geolocation.longitude || undefined;

  // Construct the Google Maps URL using the property's latitude and longitude
  // TODO: Disable the google map integration for now.
  const googleMapsSrc = null;

  const createMarkup = (text) => {
    return { __html: getHTML(text) };
  };

  const addressObject = property && property.address ? property.address : null;
  const fullAddress = addressObject && `${addressObject.unitNumber ? addressObject.unitNumber + '/' : ''}${addressObject.streetNumber} ${addressObject.street}, ${addressObject.suburb.name}, ${addressObject.state.abbreviation} ${addressObject.suburb.postcode}, ${addressObject.country.name}`
  console.log('property', property);

  // Extract agent information
  const agent = property && property.contactStaff && property.contactStaff[0] ? property.contactStaff[0] : null;
  console.log('agent', agent);
  const agentName = agent ? (agent.firstName + ' ' + agent.lastName) : undefined;
  const agentEmail = agent ? agent.email : undefined;
  const agentPhoto = agent ? (agent.photo.thumb_360) : undefined;
  const getAgentPhone = () => {
    if (!agent || agent.phoneNumbers.length === 0) {
      return undefined;
    }

    if (agent.phoneNumbers.length === 2) {
      return agent.phoneNumbers[1].number;
    } else {
      return agent.phoneNumbers[0].number;
    }
  };
  const agentPhone = getAgentPhone();
  console.log('agent details', agentName, agentEmail, agentPhone);

  const getPropertyAddress = useCallback(
    () => {
      return property.displayAddress;
    },
    [property.displayAddress],
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const $ = window.$;

    // Get the form.
    var form = $('#contact-form');

    if(!property || !getPropertyAddress()) {
      return;
    }

    // Set up an event listener for the contact form.
    $(form).submit((e) => {
      // Stop the browser from submitting the form.
      e.preventDefault();

      setIsSubmitting(true);
      setShowAlert(false);

      // Serialize the form data.
      const formData = 'address=' + getPropertyAddress() + '&' + $(form).serialize();
      sendEmail(formData, `Enquire About ${type === 'sold' ? 'a Sold' : 'Buying a'} Property`)
        .then(response => {
          setAlertMessage('Email sent successfully');
          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 5000);
          console.log('Email sent successfully:', response.data);
        })
        .catch(error => {
          setAlertMessage('Error sending email. Please call us instead.');
          setShowAlert(true)
          console.error('Error sending email:', error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });

      console.log('DEBUG >> formData', formData);
    })
  }, [property]);

  const formatInspectionDate = (dateString) => {
    const date = new Date(dateString);
    return {
      day: date.toLocaleString('en-US', { weekday: 'short' }),
      date: date.getDate(),
      month: date.toLocaleString('en-US', { month: 'short' }),
      time: date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    };
  };

  const getUpcomingInspections = () => {
    console.log('Shop_Details_Sale >> inspections', inspections);
    if (inspections) {
      return inspections.slice(0, 2); // Get the first two upcoming inspections
    }
    return null;
  };

  const InspectionDetails = () => (
    <div className="ltn__inspection-widget">
    {getUpcomingInspections() && getUpcomingInspections().length > 0 && (
      <>
        <h4 className="title-2">Inspection details</h4>
        <div className="inspection-cards">
          {getUpcomingInspections().map((inspection, index) => {
            const formattedDate = formatInspectionDate(inspection.start);
            return (
              <div key={index} className="inspection-card">
                <div className="inspection-card__date">
                  <span className="inspection-card__day">{formattedDate.day}</span>
                  <span className="inspection-card__date-number">{formattedDate.date}</span>
                  <span className="inspection-card__month">{formattedDate.month}</span>
                </div>
                <div className="inspection-card__time">
                  <span>{formattedDate.time} - </span>
                  <span>{new Date(inspection.end).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span>
                  </div>
                </div>
              );
          })}
        </div>
      </>
    )}
    </div>
  );

  return (
    <div className="ltn__shop-details-area pb-10" style={{ paddingTop: `${props.paddingTop}` }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="ltn__shop-details-inner mb-60">
              <div className="ltn__blog-meta">
                <ul>
                  {
                    getCategory() !== 'Sold' &&
                    <li className="ltn__blog-category">
                      <Link to="#">Featured</Link>
                    </li>
                  }
                  <li className="ltn__blog-category">
                    {
                      type === 'sold'
                        ? <Link className="bg-red" to="#">{getCategory()}</Link>
                        : <Link className="bg-orange" to="#">{getCategory()}</Link>
                    }
                  </li>
                </ul>
              </div>
              <h1>{property && property.displayAddress}</h1>
              <label>
                <span className="ltn__secondary-color">
                  <i className="flaticon-pin" style={{ verticalAlign: 'middle' }}/>
                </span>
                {fullAddress}
              </label>
              <br/>
              {
                property && property.soiUrl &&
                <label>
                  <span className="ltn__secondary-color">
                    <i className="flaticon-google-docs" style={{ verticalAlign: 'middle' }}/>
                  </span>
                  <a href={property.soiUrl}> View agent price guide</a>
                </label>
              }
              
              {/* Inspection Details for mobile */}
              <div className="d-md-none mobile-inspection-widget">
                <InspectionDetails />
              </div>

              {property && property.description && (
                <>
                  <h4 className="title-2" id="description">Description</h4>
                  <div dangerouslySetInnerHTML={createMarkup(property.description)}/>
                </>
              )}

              <h4 className="title-2">Property Features</h4>
              <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                {
                  property && property.landArea && property.landArea.value > 0 &&
                  <ul>
                    <li><label>Building size: </label> <span>{property.landArea.value} {property.landArea.units}</span>
                    </li>
                  </ul>
                }
                {
                  property && property.floorArea && property.floorArea.value > 0 &&
                  <ul>
                    <li><label>Building size: </label>
                      <span>{property.floorArea.value} {property.floorArea.units}</span></li>
                  </ul>
                }

                <ul>
                  <li style={{ display: 'flex', alignItems: 'center', lineHeight: '1.6', marginRight: '15px' }}>
                    <i className="flaticon-bed" style={{ marginRight: '8px', fontSize: '1.6em', display: 'flex' }}></i>
                    <label>Rooms:</label>
                    <span>{property && property.bed}</span>
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center', lineHeight: '1.6', marginRight: '15px' }}>
                    <i className="flaticon-clean"
                       style={{ marginRight: '8px', fontSize: '1.6em', display: 'flex' }}></i>
                    <label>Baths:</label>
                    <span>{property && property.bath}</span>
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center', lineHeight: '1.6', marginRight: '15px' }}>
                    <i className="flaticon-car" style={{ marginRight: '8px', fontSize: '1.6em', display: 'flex' }}></i>
                    <label>Carpark:</label>
                    <span>{property && property.carSpaces || property.carports || property.garages || property.openSpaces}</span>
                  </li>
                </ul>
              </div>
              {
                property && property.photos && property.photos.length > 2 &&
                <>
                  <h4 className="title-2" id="photos">Gallery</h4>
                  <div className="ltn__property-details-gallery mb-30">
                    <div className="row">
                      {
                        property.photos.map((photo, index) => {
                          if (photo.type === 'Floorplan') {
                            return null;
                          } else {
                            return <div className="col-md-6">
                              <a href={photo.url} data-rel="lightcase:photo">
                                <img className="mb-30" src={photo.url} alt="Image"/>
                              </a>
                            </div>
                          }
                        })
                      }
                    </div>
                  </div>
                </>
              }

              {
                googleMapsSrc &&
                <>
                  <h4 className="title-2">Location</h4>
                  <div className="property-details-google-map mb-60">
                    <iframe
                      src={googleMapsSrc}
                      width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0}/>
                  </div>
                </>
              }
              {
                floorPlans.length > 0 &&
                <>
                  <h4 className="title-2" id="floorplan">Floor Plans</h4>
                  {/* APARTMENTS PLAN AREA START */}
                  <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                    <div
                      className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center---">
                      <div className="nav">
                        {floorPlans.map((plan, index) => (
                          <a key={index} data-bs-toggle="tab" href={`#floorplan_tab_${index}`}
                             className={index === 0 ? 'active show' : ''}>
                            {`Floor ${index + 1}`}
                          </a>
                        ))}
                      </div>
                    </div>
                    <div className="tab-content">
                      {floorPlans.map((plan, index) => (
                        <div key={index} className={`tab-pane fade ${index === 0 ? 'active show' : ''}`}
                             id={`floorplan_tab_${index}`}>
                          <div className="ltn__apartments-tab-content-inner">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="apartments-plan-img">
                                  <img src={plan.url} alt={`Floor plan ${index + 1}`}/>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
              {/* Author Widget */}
              <div className="widget ltn__author-widget">
                <div className="ltn__author-widget-inner text-center"
                     style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img src={agentPhoto ?? publicUrl + 'assets/img/team/chloe_zang.jpg'} alt="Image"/>
                  <h5>{agentName}</h5>
                  {/*<small>Traveller/Photographer</small>*/}
                  <div className="product-ratting">
                    <ul>
                      <li><a href="#"><i className="fas fa-star"/></a></li>
                      <li><a href="#"><i className="fas fa-star"/></a></li>
                      <li><a href="#"><i className="fas fa-star"/></a></li>
                      <li><a href="#"><i className="fas fa-star"/></a></li>
                      <li><a href="#"><i className="fas fa-star"/></a></li>
                    </ul>
                  </div>
                  <div className="contact-info"
                       style={{ with: '60%', display: 'flex', flexDirection: 'column', alignItems: 'self-start' }}>
                    <p style={{ marginBottom: '5px' }}>
                      <i className="fab icon-mail" style={{ paddingRight: '1em' }}/>
                      <a href={`mailto:${agentEmail}`}>{agentEmail}</a>
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <i className="fab icon-phone-call" style={{ paddingRight: '1em' }}/>
                      <a href={`tel:${agentPhone}`}>{agentPhone}</a>
                    </p>
                  </div>
                </div>
              </div>
              
              {/* Inspection Details for desktop */}
              <div className="d-none d-md-block">
                <InspectionDetails />
              </div>

              {/* Form Widget */}
              <div className="widget ltn__form-widget">
                <h4 className="ltn__widget-title ltn__widget-title-border-2">Enquiry or Book an Inspection</h4>
                <form id="contact-form" method="post">
                  <input type="text" name="name" placeholder="Your Name"/>
                  <input type="text" name="email" placeholder="Your e-Mail*" required/>
                  <input type="text" name="phone" placeholder="Your phone number*" required/>
                  <textarea name="message" placeholder="Write Message..." defaultValue={''}/>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                      type="submit"
                      className="btn theme-btn-1"
                      disabled={isSubmitting}>
                      Send
                    </button>
                  </div>
                </form>
                {showAlert && <AlertMessage message={alertMessage} type={alertMessage.startsWith('Error') ? 'error' : 'success'} />}
              </div>
              {/* Banner Widget */}
              <div className="widget ltn__banner-widget d-none go-top">
                <Link to="/shop"><img src={publicUrl + 'assets/img/banner/2.jpg'} alt="#"/></Link>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShopDetailsSale
