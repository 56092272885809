import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import { Link } from 'react-router-dom';
import CallToActionV1 from './section-components/call-to-action-v1';
import { Helmet } from 'react-helmet';

const PropertyManagementServices = () => {
  let publicUrl = process.env.PUBLIC_URL + '/'

  return (
    <div>
      <Helmet>
        <title>Hoyee Property Group | Property Management Services | Australia</title>
      </Helmet>

      <Navbar/>
      <PageHeader headertitle="" subheader="Property Management Services"/>

      <>
        <div className="ltn__blog-details-wrap">
          <div className="ltn__page-details-inner ltn__blog-details-inner">
            <h1 className="ltn__blog-title" style={{ textAlign: 'center' }}>
              PROPERTY MANAGEMENT SERVICES
            </h1>
            <p style={{ textAlign: 'center' }}>
              <strong>It's All in the Details</strong>
            </p>
            <p>
              As part of a team that has been providing professional real estate services, HOYEE Property Management
              offers exceptional service and astute industry knowledge for all Melbourne Metropolitan Area properties.
            </p>
            <p>
              HOYEE works to ensure that we maximise your rental return whilst efficiently and effectively managing your
              investment to ensure that your asset is well maintained to maximise capital growth.
            </p>
            <ul>
              <li>7 day a week leasing services</li>
              <li>Experienced leasing team</li>
              <li>Multilingual staff</li>
              <li>Well trained professional teams that understand the market</li>
              <li>Consistently low vacancy rates</li>
              <li>Marketing campaigns to maximise your property’s exposure</li>
              <li>An extensive range of services including property management, leasing, insurance, maintenance, finance
                and sales
              </li>
            </ul>
            <h3 style={{ textAlign: 'center' }}>
              Our Property Management Team
            </h3>
            <p>
              HOYEE has been a market leader in providing quality property management services. Our size, reputation and
              infrastructure allow us to attract and retain the best people in the industry. Our team of qualified and
              experienced property managers is committed to providing you with professional advice
              and the highest level of customer service.
            </p>
            <h3 style={{ textAlign: 'center' }}>
              Our LeasingTeam
            </h3>
            <p>
              HOYEE leasing team will find the right renters for your property. With a dedicated team of leasing
              professionals, we have the resources to source and select the best quality renters for our clients.
              Operating 7 days a week, the leasing team is focused on providing renters with assistance with relocations
              and furnished options, fast lease approvals and leasing advice.
            </p>
            <h3 style={{ textAlign: 'center' }}>
              Our Property Management Finance Team
            </h3>
            <p>
              Under the guidance of our qualified accountants, our team of finance professionals ensures all rental
              providers accounts are managed according to best practice standards. Quality procedures are in place for
              the
              collection of rental and arrears, cash management and statement production, ensuring the prompt and
              accurate
              management and transfer of rental providers’ funds.
            </p>

            <h3 style={{ textAlign: 'center' }}>
              Free Rental Appraisal
            </h3>
            <p>
              <strong className="go-top">
                <Link to="/appraisal-rental" style={{ textDecoration: 'underline' }}>
                  Click for A Free Rental Appraisal
                </Link>
              </strong>
            </p>
          </div>
        </div>
      </>
      <CallToActionV1
        title={'Considering renting out your property?'}
        subtitle={'We can help you'}
        linkText={'Contact Us'}
        link={'/contact'}
      />
      <Footer/>
    </div>
  )
}

export default PropertyManagementServices
