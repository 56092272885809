import React, { useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { autorun, toJS } from 'mobx';
import { fetchProperties } from '../../utility';
import { fetchResidentialPropertiesSold } from '../../api';
import useStore from '../../store';

const CounterV1 = () => {

  let publicUrl = process.env.PUBLIC_URL + '/'

  const [propertiesSold, setPropertiesSold] = useState([]);
  const [soldPropertyDisplayAddresses, setSoldPropertyDisplayAddresses] = useState([]);
  const { globalStore } = useStore();

  useEffect(() => {
    const disposerForSold = autorun(() => {
      const properties = toJS(globalStore.allSoldProperties);
      console.log('counter useEffect >> sold properties', properties);

      // If sell properties have been fetched, set them to propertiesSell
      // Otherwise, fetch them
      if (properties && properties.length > 0) {
        setPropertiesSold(properties);
        console.log('counter sold >> properties', properties);
      } else {
        const propertiesSoldPromise = fetchProperties(fetchResidentialPropertiesSold, {
          pagesize: 200,
          sort: 'modified',
        });
        propertiesSoldPromise
          .then(propertiesSold => {
            setPropertiesSold(propertiesSold);
            globalStore.allSoldProperties.length === 0 && globalStore.setSoldProperties(propertiesSold);

            console.log('sold', propertiesSold);
          })
          .catch(error => {
            console.log('DEBUG >> sold >> Error fetching propertiesSold', error);
          });
      }
    });
    return () => {
      disposerForSold();
    };
  }, [globalStore.allSoldProperties]);

  useEffect(() => {
    const addresses = propertiesSold.map(property => {
      return property.displayAddress;
    });

    setSoldPropertyDisplayAddresses(addresses);

  }, [propertiesSold]);

  return (
    <>
      <div>
        <div className="row counter-margin-top">
          <hr style={{ borderTop: '0px solid #9B9B9B', marginBottom: '1px' }}/>
          <Marquee style={{ paddingTop: '24px', paddingBottom: '24px' }}
                   gradient={false}
                   className="" // Override default .overlay style class
          >
            {
              soldPropertyDisplayAddresses.map((address, index) => {
                return (
                <>
                  <span key={index} style={{ paddingRight: '5px', verticalAlign: 'middle' }}>
                    <b>Sold: </b>
                  {address}
                  </span>
                  <img style={{ padding: '0 5px', width: '18px' }}
                       src={publicUrl + 'assets/fonts/arrow_right.svg'}/>
                </>
                )
              })
          }
          </Marquee>
          <hr style={{ borderTop: '1px solid #9B9B9B', marginBottom: '1px' }}/>
        </div>
      </div>
    </>
  )
}

export default CounterV1
