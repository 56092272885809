import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useStore from '../../store';
import {
  PropertyCardLease,
  PropertyCardSale,
  PropertyCardSold,
  PropertyListItemLease,
  PropertyListItemSale
} from './PropertyBlock';
import { autorun } from 'mobx';
import {
  extractLeasingPropertyAddress,
  extractLeasingPropertyCarSpaces,
  extractLeasingPropertyCategory,
  extractLeasingPropertyRent,
} from '../../utility';
import { fetchPropertiesLease, fetchPropertiesSale, fetchResidentialPropertiesSold, fetchPropertiesOpenHomes } from '../../api';
import { fetchProperties, filterLeasingImagesBySize } from '../../utility';

const ShopGridV1 = (props) => {
  const [propertiesSell, setPropertiesSell] = useState([]);
  const [propertiesLease, setPropertiesLease] = useState([]);
  const [propertiesSold, setPropertiesSold] = useState([]);

  const [oriPropertiesSell, setOriPropertiesSell] = useState([]);
  const [oriPropertiesLease, setOriPropertiesLease] = useState([]);
  const [oriPropertiesSold, setOriPropertiesSold] = useState([]);

  const noPropertyFound = React.useRef(false);
  const setNoPropertyFound = (value) => {
    noPropertyFound.current = value;
  }

  const hasFetchDataCompletedForThisPage = React.useRef(false);
  const setHasFetchDataCompletedForThisPage = (value) => {
    hasFetchDataCompletedForThisPage.current = value;
    props.onFetchComplte();
  }

  const skeletonProperties = [null, null, null, null, null, null, null, null, null, null];
  const { globalStore } = useStore();
  let publicUrl = process.env.PUBLIC_URL + '/'

  const location = useLocation();

  const [searchType, setSearchType] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const [hasFetchedLeaseProperties, setHasFetchedLeaseProperties] = useState(false);
  const [hasFetchedSellProperties, setHasFetchedSellProperties] = useState(false);
  const [hasFetchedSoldProperties, setHasFetchedSoldProperties] = useState(false);

  const [propertiesInspections, setPropertiesInspections] = useState({});
  const [fetchedInspections, setFetchedInspections] = useState(false);

  const fetchInspections = useCallback(async (propertyIds) => {
    if (!fetchedInspections) {
      try {
        const startingAfter = new Date().toISOString();
        const startingBefore = new Date(Date.now() + 21 * 24 * 60 * 60 * 1000).toISOString();
        console.log('DEBUG >> fetchInspections >> propertyIds', propertyIds, startingAfter);
        const response = await fetchPropertiesOpenHomes({ 
          properties: propertyIds, 
          startingAfter, 
          startingBefore 
        });
        console.log('DEBUG >> fetchInspections >> response', response);
        const inspectionsMap = {};
        if (response && response.items) {
          response.items.forEach(inspection => {
            const propertyId = inspection.property.id;
            if (!inspectionsMap[propertyId]) {
              inspectionsMap[propertyId] = [];
            }
            inspectionsMap[propertyId].push(inspection);
          });
        }
        console.log('DEBUG >> fetchInspections >> inspectionsMap', inspectionsMap);
        setPropertiesInspections(inspectionsMap);
        setFetchedInspections(true);
      } catch (error) {
        console.error('Error fetching property inspections:', error);
      }
    }
  }, [fetchedInspections]);

  useEffect(() => {
    if (props.type === 'buy' && propertiesSell.length > 0 && !fetchedInspections) {
      const propertyIds = propertiesSell.map(property => property.id);
      fetchInspections(propertyIds);
    }
  }, [props.type, propertiesSell, fetchedInspections, fetchInspections]);

  useEffect(() => {
    console.log('DEBUG >> shop-grid-v1 >> props.searchOptions', props.searchOptions);
    let filteredProperties = [];
    if(props.type === 'buy') {
      filteredProperties = filterByFilterFormQuery(oriPropertiesSell, props.type);
      setPropertiesSell(filteredProperties);
    } else if(props.type === 'sold') {
      filteredProperties = filterByFilterFormQuery(oriPropertiesSold, props.type);
      setPropertiesSold(filteredProperties);
    } else if(props.type === 'rent') {
      filteredProperties = filterByFilterFormQuery(oriPropertiesLease, props.type);
      setPropertiesLease(filteredProperties);
    }
    if(hasFetchDataCompletedForThisPage.current && filteredProperties.length === 0) {
      console.log('DEBUG >> shop-grid-v1 >> no property found', hasFetchDataCompletedForThisPage.current, filteredProperties.length);
      setNoPropertyFound(true);
    } else {
      setNoPropertyFound(false);
    }
  }, [props.searchOptions, props.type, oriPropertiesSell, oriPropertiesSold, oriPropertiesSold]);

  const parseQueryParams = (search) => {
    const params = new URLSearchParams(search);
    setSearchType(params.get('type'));
    setSearchQuery(params.get('query'));
  };

  useEffect(() => {
    parseQueryParams(location.search);
    console.log('DEBUG >> query params => ', location.search, searchType, searchQuery);
  }, [location]);

  const filterByHomeSearchQuery = useCallback((properties, status) => {
    let filteredProperties = properties;

    if (searchQuery === '' && searchType === '' || (!searchQuery && !searchType)) {
      return filteredProperties;
    }

    if (status === 'buy' || status === 'sold') {
      filteredProperties = filteredProperties.filter(property => {
        const propertyTypeName = property.type.name.toLowerCase();
        const propertyClassName = property.type.propertyClass?.name?.toLowerCase();
        const propertyClassInternalName = property.type.propertyClass?.internalName?.toLowerCase();
        return (
          propertyTypeName === searchType?.toLowerCase() ||
          propertyClassName === searchType?.toLowerCase() ||
          propertyClassInternalName === searchType?.toLowerCase()
        )});

      filteredProperties = filteredProperties.filter(property => {
        const address = property.displayAddress;
        console.log('DEBUG >> address', address, 'searchQuery', searchQuery);
        return address.toLowerCase().includes(searchQuery?.toLowerCase());
      });
    } else {
      filteredProperties = filteredProperties.filter(property => {
        return extractLeasingPropertyCategory(property).toLowerCase() === searchType?.toLowerCase();
      });

      filteredProperties = filteredProperties.filter(property => {
        const address = extractLeasingPropertyAddress(property).toLowerCase();
        console.log('DEBUG >> address', address, 'searchQuery', searchQuery);
        return address.toLowerCase().includes(searchQuery?.toLowerCase());
      });
    }

    return filteredProperties;
  }, [searchQuery, searchType]);

  const filterByFilterFormQuery = useCallback((properties, status) => {
    let filteredProperties = properties;

    console.log('DEBUG >> filterByFilterFormQuery >> props.searchOptions', props.searchOptions)

    if (
      props.searchOptions.propertyType === 'All' &&
      props.searchOptions.bedrooms === 'Bed' &&
      props.searchOptions.bathrooms === 'Bath' &&
      props.searchOptions.carSpaces === 'Car' &&
      props.searchOptions.maxPrice.includes('Max')
    ) {
      return filteredProperties;
    }

    if (props.searchOptions.propertyType !== 'All') {
      filteredProperties = filteredProperties.filter(property => {
        if (status === 'buy' || status === 'sold') {
          const value = props.searchOptions.propertyType?.toLowerCase();
          const propertyTypeName = property.type.name?.toLowerCase();
          const propertyClassName = property.type.propertyClass?.name?.toLowerCase();
          const propertyClassInternalName = property.type.propertyClass?.internalName?.toLowerCase();
          console.log('DEBUG >> property.type', property.type, 'propertyClassName', propertyClassName, property.propertyClass?.name);
          return (
            propertyTypeName === value ||
            propertyClassName === value ||
            propertyClassInternalName === value
          );
        } else {
          console.log('DEBUG >> extractLeasingPropertyCategory(property)', extractLeasingPropertyCategory(property));
          console.log('DEBUG >> props.searchOptions.propertyType', props.searchOptions.propertyType);
          return extractLeasingPropertyCategory(property).toLowerCase() === props.searchOptions.propertyType?.toLowerCase();
        }
      });
    }

    if (props.searchOptions.bedrooms !== 'Bed') {
      const value = parseInt(props.searchOptions.bedrooms);
      filteredProperties = filteredProperties.filter(property => {
        if (status === 'buy' || status === 'sold') {
          return property.bed >= value;
        } else {
          return property.features[0].bedrooms[0] >= value;
        }
      });
    }

    if (props.searchOptions.bathrooms !== 'Bath') {
      const value = parseInt(props.searchOptions.bathrooms);
      filteredProperties = filteredProperties.filter(property => {
        if (status === 'buy' || status === 'sold') {
          return property.bath >= value;
        } else {
          return property.features[0].bathrooms[0] >= value;
        }
      });
    }

    if (props.searchOptions.carSpaces !== 'Car') {
      const value = parseInt(props.searchOptions.carSpaces);
      filteredProperties = filteredProperties.filter(property => {
        if (status === 'buy' || status === 'sold') {
          const { carSpaces, carports, garages, openSpaces } = property;
          return carSpaces >= value || carports >= value || garages >= value || openSpaces >= value;
        } else {
          return extractLeasingPropertyCarSpaces(property) >= value;
        }
      });
    }

    if (!props.searchOptions.maxPrice.includes('Max')) {
      const value = parseInt(
        props
          .searchOptions
          .maxPrice
          .replace(/[^0-9]/g, ''), 10);

      filteredProperties = filteredProperties.filter(property => {
        if (status === 'buy' || status === 'sold') {
          let minPrice = Infinity;
          if (property.displayPrice === 'Contact Agent') {
            minPrice = 0;
          } else {
            if (property.displayPrice.includes('-')) {
              const prices = property.displayPrice.split('-');
              minPrice = parseInt(prices[0].replace(/[^0-9]/g, ''), 10);
            } else {
              minPrice = parseInt(property.displayPrice.replace(/[^0-9]/g, ''), 10);
            }
          }
          return minPrice <= value;
        } else {
          const rent = parseInt(extractLeasingPropertyRent(property).match(/\d+/)[0], 10);
          console.log('DEBUG >> rent', rent, 'value', value)
          return rent <= value;
        }
      });
    }

    filteredProperties = filteredProperties.sort((a, b) => {
      const bedA = status === 'rent'
      ? a.features[0].bedrooms[0] || 0
      : a.bed;
      const bedB = status === 'rent'
      ? b.features[0].bedrooms[0] || 0
      : b.bed;

      const carA = status === 'rent'
      ? extractLeasingPropertyCarSpaces(a) || 0
      : a.carSpaces || a.carports || a.garages || a.openSpaces || 0;
      const carB = status === 'rent'
        ? extractLeasingPropertyCarSpaces(b) || 0
        : b.carSpaces || b.carports || b.garages || b.openSpaces || 0;

      const bathA = status === 'rent'
      ? a.features[0].bathrooms[0] || 0
      : a.bath || a.features[0].bathrooms[0] || 0;
      const bathB = status === 'rent'
        ? b.features[0].bathrooms[0] || 0
        : b.bath || b.features[0].bathrooms[0] || 0;

      let minPriceA = 0;
      if(status === 'rent') {
          minPriceA = extractLeasingPropertyRent(a) === 'Contact Agent' ? 0 : parseInt(extractLeasingPropertyRent(a).match(/\d+/)[0], 10)
      } else if(a.displayPrice !== 'Contact Agent') {
        if (a.displayPrice.includes('-')) {
          const prices = a.displayPrice.split('-');
          minPriceA = parseInt(prices[0].replace(/[^0-9]/g, ''), 10);
        } else {
          minPriceA = parseInt(a.displayPrice.replace(/[^0-9]/g, ''), 10);
        }
      }

      let minPriceB = 0;
      if(status === 'rent') {
        minPriceB = extractLeasingPropertyRent(b) === 'Contact Agent' ? 0 : parseInt(extractLeasingPropertyRent(b).match(/\d+/)[0], 10)
      } else if(b.displayPrice !== 'Contact Agent') {
        if (b.displayPrice.includes('-')) {
          const prices = b.displayPrice.split('-');
          minPriceB = parseInt(prices[0].replace(/[^0-9]/g, ''), 10);
        } else {
          minPriceB = parseInt(b.displayPrice.replace(/[^0-9]/g, ''), 10);
        }
      }

      if (bedA !== bedB) {
        return bedA - bedB;
      }

      if (bathA !== bathB) {
        return bathA - bathB;
      }

      if(carA !== carB) {
        return carA - carB;
      }

      return minPriceA - minPriceB;
    });

    return filteredProperties;
  }, [
    props.searchOptions.propertyType,
    props.searchOptions.bedrooms,
    props.searchOptions.bathrooms,
    props.searchOptions.carSpaces,
    props.searchOptions.maxPrice,
    props.searchOptions.maxPrice
  ]);

  useEffect(() => {
    console.log('DEBUG >> query params changed => ', searchType, searchQuery);
    const disposerForSell = autorun(() => {
      let properties = globalStore.allSellProperties;
      console.log('shop-grid-v1 useEffect >> sell properties', properties);

      if (properties.length > 0) {
        let filteredProperties = properties;
        if (props.type === 'buy') {
          filteredProperties = filterByHomeSearchQuery(properties, 'buy');
        }
        setPropertiesSell(filteredProperties);
        setOriPropertiesSell(filteredProperties);

        filteredProperties.length === 0 && setNoPropertyFound(true);

        if (props.type === 'buy') {
          setHasFetchDataCompletedForThisPage(true);
        }

        console.log('shop-grid-v1 sell', properties);
      } else if (!hasFetchedSellProperties) {
        const propertiesSellPromise = fetchProperties(fetchPropertiesSale, {
          pagesize: 200,
          sort: 'modified',
          status: 'listing',
          published: true,
        });
        propertiesSellPromise
          .then(propertiesSell => {
            propertiesSell.reverse();
            globalStore.allSellProperties.length === 0 && globalStore.setSellProperties(propertiesSell);

            let filteredProperties = propertiesSell;

            if (props.type === 'buy') {
              filteredProperties = filterByHomeSearchQuery(filteredProperties, 'buy');
            }

            setPropertiesSell(filteredProperties);
            setOriPropertiesSell(filteredProperties)

            filteredProperties.length === 0 && setNoPropertyFound(true);

            console.log('sell', propertiesSell);
          })
          .catch(error => {
            console.log('Error fetching propertiesSell', error);
          })
          .finally(() => {
            if (props.type === 'buy') {
              setHasFetchDataCompletedForThisPage(true);
            }
          });

        setHasFetchedSellProperties(true);
      }
    });

    const disposerForSold = autorun(() => {
      let properties = globalStore.allSoldProperties;
      console.log('shop-grid-v1 useEffect >> sold properties', properties);

      if (properties.length > 0) {
        let filteredProperties = properties;
        if (props.type === 'sold') {
          filteredProperties = filterByHomeSearchQuery(properties, 'sold');
        }
        setPropertiesSold(filteredProperties);
        setOriPropertiesSold(filteredProperties);

        filteredProperties.length === 0 && setNoPropertyFound(true);

        if (props.type === 'sold') {
          setHasFetchDataCompletedForThisPage(true);
        }

        console.log('shop-grid-v1 sold >> properties', properties);
      } else if (!hasFetchedSoldProperties) {
        const propertiesSoldPromise = fetchProperties(fetchResidentialPropertiesSold, {
          pagesize: 200,
          sort: 'modified',
        });
        propertiesSoldPromise
          .then(propertiesSold => {

            globalStore.allSoldProperties.length === 0 && globalStore.setSoldProperties(propertiesSold);

            let filteredProperties = propertiesSold;
            if (props.type === 'sold') {
              filteredProperties = filterByHomeSearchQuery(propertiesSold, 'sold');
            }
            setPropertiesSold(filteredProperties);
            setOriPropertiesSold(filteredProperties);
            filteredProperties.length === 0 && setNoPropertyFound(true);
            console.log('sold', propertiesSold);
          })
          .catch(error => {
            console.log('DEBUG >> sold >> Error fetching propertiesSold', error);
          })
          .finally(() => {
            if (props.type === 'sold') {
              setHasFetchDataCompletedForThisPage(true);
            }
          });

        setHasFetchedSoldProperties(true);
      }
    });

    const disposerForLease = autorun(() => {
      const properties = globalStore.allLeasingProperties;

      if (properties.length > 0) {
        let filteredProperties = properties;
        if (props.type === 'rent') {
          filteredProperties = filterByHomeSearchQuery(properties, 'rent');
        }
        setPropertiesLease(filteredProperties);
        setOriPropertiesLease(filteredProperties);

        filteredProperties.length === 0 && setNoPropertyFound(true);

        if (props.type === 'rent') {
          setHasFetchDataCompletedForThisPage(true);
        }

      } else if (!hasFetchedLeaseProperties) {

        const propertiesLeasingPromise = fetchProperties(fetchPropertiesLease);
        propertiesLeasingPromise
          .then(async propertiesLeasing => {
            console.log('DEBUG >> propertiesLeasing', propertiesLeasing);

            if (!propertiesLeasing || propertiesLeasing.length === 0) {
              setNoPropertyFound(true);
              return;
            }

            propertiesLeasing.sort((a, b) => {
              const dateA = new Date(a.dateAvailable[0]);
              const dateB = new Date(b.dateAvailable[0]);
              return dateB - dateA;
            });

            propertiesLeasing = await filterLeasingImagesBySize(propertiesLeasing);

            if (props.type === 'rent') {
              propertiesLeasing = filterByHomeSearchQuery(propertiesLeasing, 'rent');
            }

            setPropertiesLease(propertiesLeasing);
            setOriPropertiesLease(propertiesLeasing);
            propertiesLeasing.length === 0 && setNoPropertyFound(true);

            globalStore.allLeasingProperties.length === 0 && globalStore.setLeasingProperties(propertiesLeasing);
          })
          .catch(error => {
            console.log('Error fetching propertiesLease', error);
          })
          .finally(() => {
            if (props.type === 'rent') {
              setHasFetchDataCompletedForThisPage(true);
            }
          });

        setHasFetchedLeaseProperties(true);
      }
    });

    return () => {
      disposerForSell();
      disposerForSold();
      disposerForLease();
    };
  }, [
    globalStore.allSellProperties, globalStore.allSoldProperties,
    globalStore.allLeasingProperties, filterByHomeSearchQuery,
    searchType, searchQuery,
    hasFetchedLeaseProperties,
    hasFetchedSellProperties,
    hasFetchedSoldProperties
  ]);

  console.log('props.type', props.type);

  const CardItem = useMemo(() => {
    console.log('CardItem props.type', props.type);
    if (noPropertyFound.current) {
      return (
        <div className="col-lg-12">
          <div className="ltn__alert alert-danger alert-dismissible fade show" role="alert" style={{
            fontSize: '18px',
            color: '#721c24',
            backgroundColor: '#f8d7da',
            borderColor: '#f5c6cb',
            padding: '10px',
            border: '1px solid transparent',
            borderRadius: '4px',
            margin: '10px 0',
          }}>
            <strong style={{ marginRight: '10px' }}>
              <i className="fas fa-exclamation-triangle" style={{ marginRight: '5px' }}></i>Sorry!
            </strong>
            No property found.
          </div>
        </div>
      );
    }
    if (props.type === 'buy') {
      console.log('Rendering sell property >> type', props);
      console.log('Rendering sell property >> propertiesSell', propertiesSell);
      return (
        propertiesSell.length === 0
          ? skeletonProperties.map((property, index) => (
              <PropertyCardSale 
                key={`skeleton-card-item-${index}`} 
                property={property} 
                index={index}
                inspections={[]}
              />
            ))
          : propertiesSell.map((property, index) => (
              <PropertyCardSale 
                key={property.id} 
                property={property} 
                index={index}
                inspections={propertiesInspections[property.id] || []}
              />
            ))
      );
    }
    if (props.type === 'sold') {
      console.log('Rendering sold property >> type', props);
      console.log('Rendering sold property >> propertiesSold', propertiesSold);
      return (
        propertiesSold.length === 0
          ? skeletonProperties.map((property, index) => (
              <PropertyCardSold key={`skeleton-card-item-${index}`} property={property} index={index}/>
            ))
          : propertiesSold.map((property, index) => (
              <PropertyCardSold key={property.id} property={property} index={index}/>
            ))
      );
    } else if (props.type === 'rent') {
      console.log('Rendering rent property');
      return (
        propertiesLease.length === 0
          ? skeletonProperties.map((property, index) => (
              <PropertyCardLease key={`skeleton-card-item-${index}`} property={property} index={index}/>
            ))
          : propertiesLease.map((property, index) => (
              <PropertyCardLease key={`skeleton-card-item-${index}`} property={property} index={index}/>
            ))
      );
    } else {
      console.log('Rendering unknown type property', props.type);
      return <></>
    }
  }, [props.type, propertiesSell, propertiesInspections, noPropertyFound, propertiesSold, propertiesLease, skeletonProperties]);

  const ListItem = useMemo(() => {
    console.log('ListItem props.type', props.type);
    if (noPropertyFound.current) {
      return (
        <div className="col-lg-12">
          <div className="ltn__alert alert-danger alert-dismissible fade show" role="alert" style={{
            fontSize: '18px',
            color: '#721c24',
            backgroundColor: '#f8d7da',
            borderColor: '#f5c6cb',
            padding: '10px',
            border: '1px solid transparent',
            borderRadius: '4px',
            margin: '10px 0',
          }}>
            <strong style={{ marginRight: '10px' }}>
              <i className="fas fa-exclamation-triangle" style={{ marginRight: '5px' }}></i>Sorry!
            </strong>
            No property found.
          </div>
        </div>
      );
    }

    if (props.type === 'sell') {
      console.log('Rendering sell property >> type', props);
      console.log('Rendering sell property >> propertiesSell', propertiesSell);
      return (
        propertiesSell.length === 0
          ? skeletonProperties.map((property, index) => (
              <PropertyListItemSale property={property} index={index}/>
            ))
          : propertiesSell.map((property, index) => (
              <PropertyListItemSale property={property} index={index}/>
            ))
      );
    } else if (props.type === 'rent') {
      console.log('Rendering rent property');
      return (
        propertiesLease.length === 0
          ? skeletonProperties.map((property, index) => (
              <PropertyListItemLease property={property} index={index}/>
            ))
          : propertiesLease.map((property, index) => (
              <PropertyListItemLease property={property} index={index}/>
            ))
      );
    } else {
      console.log('Rendering unknown type property', props.type);
      return <></>
    }
  }, [props.type, propertiesSell, propertiesLease, noPropertyFound, skeletonProperties]);

  return (
    <div>
      <div className="ltn__product-area ltn__product-gutter mb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tab-content ">
                <div className="tab-pane fade active show" id="liton_product_grid">
                  <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                    <div className="row">
                      {CardItem}
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="liton_product_list">
                  <div className="ltn__product-tab-content-inner ltn__product-list-view">
                    <div className="row">
                      {ListItem}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ltn__modal-area ltn__add-to-cart-modal-area">
        <div className="modal fade" id="liton_wishlist_modal" tabIndex={-1}>
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="ltn__quick-view-modal-inner">
                  <div className="modal-product-item">
                    <div className="row">
                      <div className="col-12">
                        <div className="modal-product-img">
                          <img src={publicUrl + 'assets/img/product/7.png'} alt="#"/>
                        </div>
                        <div className="modal-product-info go-top">
                          <h5><Link to="/product-details">Brake Conversion Kit</Link></h5>
                          <p className="added-cart"><i className="fa fa-check-circle"/> Successfully added to your
                            Wishlist</p>
                          <div className="btn-wrapper">
                            <Link to="/wishlist" className="theme-btn-1 btn btn-effect-1">View Wishlist</Link>
                          </div>
                        </div>
                        {/* additional-info */}
                        <div className="additional-info d-none">
                          <p>We want to give you <b>10% discount</b> for your first order, <br/> Use discount code at
                            checkout</p>
                          <div className="payment-method">
                            <img src={publicUrl + 'assets/img/icons/payment.png'} alt="#"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ltn__modal-area ltn__quick-view-modal-area">
        <div className="modal fade" id="quick_view_modal" tabIndex={-1}>
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                  {/* <i class="fas fa-times"></i> */}
                </button>
              </div>
              <div className="modal-body">
                <div className="ltn__quick-view-modal-inner">
                  <div className="modal-product-item">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <div className="modal-product-img">
                          <img src={publicUrl + 'assets/img/product/4.png'} alt="#"/>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="modal-product-info">
                          <div className="product-ratting">
                            <ul>
                              <li><a href="#"><i className="fas fa-star"/></a></li>
                              <li><a href="#"><i className="fas fa-star"/></a></li>
                              <li><a href="#"><i className="fas fa-star"/></a></li>
                              <li><a href="#"><i className="fas fa-star"/></a></li>
                              <li><a href="#"><i className="fas fa-star"/></a></li>
                              <li className="review-total"><a href="#"> ( 95 Reviews )</a></li>
                            </ul>
                          </div>
                          <h3>Brake Conversion Kit</h3>
                          <div className="product-price">
                            <span>$149.00</span>
                            <del>$165.00</del>
                          </div>
                          <div className="modal-product-meta ltn__product-details-menu-1">
                            <ul>
                              <li>
                                <strong>Categories:</strong>
                                <span className="go-top">
							<Link to="/blog">Parts</Link>
							<Link to="/blog">Car</Link>
							<Link to="/blog">Seat</Link>
							<Link to="/blog">Cover</Link>
						</span>
                              </li>
                            </ul>
                          </div>
                          <div className="ltn__product-details-menu-2">
                            <ul>
                              <li>
                                <div className="cart-plus-minus">
                                  <input type="text" defaultValue="02" name="qtybutton"
                                         className="cart-plus-minus-box"/>
                                </div>
                              </li>
                              <li>
                                <a href="#" className="theme-btn-1 btn btn-effect-1" title="Add to Cart"
                                   data-bs-toggle="modal" data-bs-target="#add_to_cart_modal">
                                  <i className="fas fa-shopping-cart"/>
                                  <span>ADD TO CART</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <hr/>
                          <div className="ltn__social-media">
                            <ul>
                              <li>Share:</li>
                              <li><a href="#" title="Facebook"><i className="fab fa-facebook-f"/></a></li>
                              <li><a href="#" title="Twitter"><i className="fab fa-twitter"/></a></li>
                              <li><a href="#" title="Linkedin"><i className="fab fa-linkedin"/></a></li>
                              <li><a href="#" title="Instagram"><i className="fab fa-instagram"/></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ltn__modal-area ltn__add-to-cart-modal-area">
        <div className="modal fade" id="add_to_cart_modal" tabIndex={-1}>
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="ltn__quick-view-modal-inner">
                  <div className="modal-product-item">
                    <div className="row">
                      <div className="col-12">
                        <div className="modal-product-img">
                          <img src={publicUrl + 'assets/img/product/1.png'} alt="#"/>
                        </div>
                        <div className="modal-product-info go-top">
                          <h5 className="go-top"><Link to="/product-details">Brake Conversion Kit</Link></h5>
                          <p className="added-cart"><i className="fa fa-check-circle"/>Successfully added to your Cart
                          </p>
                          <div className="btn-wrapper">
                            <Link to="/cart" className="theme-btn-1 btn btn-effect-1">View Cart</Link>
                            <Link to="/checkout" className="theme-btn-2 btn btn-effect-2">Checkout</Link>
                          </div>
                        </div>
                        {/* additional-info */}
                        <div className="additional-info d-none">
                          <p>We want to give you <b>10% discount</b> for your first order, <br/> Use discount code at
                            checkout</p>
                          <div className="payment-method">
                            <img src={publicUrl + 'assets/img/icons/payment.png'} alt="#"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default React.memo(ShopGridV1)
