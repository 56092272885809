import React from 'react';
import ComingSoon from './section-components/coming-soon';

const ComingSoonPage = () => {
    return <div>
        <ComingSoon />
    </div>
}

export default ComingSoonPage

