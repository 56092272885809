import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ServiceV5 extends Component {

  render() {

    let publicUrl = process.env.PUBLIC_URL + '/'

    return <div className="ltn__service-area section-bg-1 pt-115 pb-70 go-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Services</h6>
              <h1 className="section-title">Our Core Services</h1>
            </div>
          </div>
        </div>
        <div className="row  justify-content-center">
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
              <div className="ltn__feature-icon">
                <span><i className="flaticon-house"/></span>
              </div>
              <div className="ltn__feature-info">
                <h3><Link to="/service-details">Property Management</Link></h3>
                <div style={{textAlign: 'left'}}>
                <p>
                  We understand the specific property management needs of investors. You seek the assurance that your
                  property is safeguarded while optimizing its investment value. Hoyee Property Group offer the highest
                  standard of service for local and overseas owners, and handle everything on your behalf. We are your
                  Melbourne property management experts!
                </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
              <div className="ltn__feature-icon">
                <span><i className="flaticon-mortgage"/></span>
              </div>
              <div className="ltn__feature-info">
                <h3><Link to="/service-details">Sales</Link></h3>
                <div style={{textAlign: 'left'}}>
                <p>
                  Hoyee Property Group has the capacity to generate maximum real estate market coverage for your
                  Melbourne property. We target genuine buyers, tailor marketing solutions to your specific needs and
                  keep you updated of every step in the process. It is our goal to achieve the best possible outcome, in
                  the fastest possible time frame.
                </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
              <div className="ltn__feature-icon">
                <span><i className="flaticon-official-documents"/></span>
              </div>
              <div className="ltn__feature-info">
                <h3><Link to="/service-details">Project Marketing</Link></h3>
                <div style={{textAlign: 'left'}}>
                <p>
                  Hoyee Property Group specialises in the sales and marketing of residential projects from concept to
                  completion. Hoyee Property Group is proud to have delivered an impressive track record of results for
                  its clients, achieved by using proven strategies, attention to detail and accurate local and offshore
                  market knowledge.
                </p>
                <p>
                  Our team work closely with the developer clients to establish the optimal design, branding, marketing
                  and sales release strategies aiming to maximize pricing and rates of sale for off-plan projects.
                </p>
                <p>
                  Outstanding results have been achieved by using proven sales & marketing strategies, attention to
                  detail and accurate local & overseas markets knowledge. Hoyee Property Group is driven to continue to
                  revolutionise and evolve project marketing for the profitability of our clients. At the heart of our
                  core philosophy lies a deep understanding of our client's specific needs, directing the collective
                  experience of our team towards delivering the desired outcomes.
                </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default ServiceV5
