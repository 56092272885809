import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';
import { createMarkup } from '../../utility';
import './testimonial-v1.css';

class Testimonial extends Component {

  constructor(props) {
    super(props);

    this.feedbacks = {
      chloe_zang: {
        img: 'assets/img/team/chloe_zang_240.jpg',
        name: 'Chloe Zang',
        data: [
          {
            client: 'Seller of property',
            content: 'I’d like to say a big thank you to Chloe for making the experience of selling my property seamless and easier than what I expected.\n' +
              '\n' +
              'She was very friendly and professional and I especially appreciated her consistent and transparent updates throughout the whole process to keep me informed of how everything was progressing as well as answering any questions along the way before signing the contract. She is very honest, knowledgeable and experienced.\n' +
              '\n' +
              'I would highly recommend her!',
          },
          {
            client: 'Seller of property',
            content: 'I͏ highly recommend Chloe Zang. Chloe has done a terrific job in selling my property. Her communication was clear and regular, she has worked with multiple buyers and ultimately achieved me the sale price that I was looking for in under a month. She delivered everything that she said. She is energetic, upfront, honest and approachable. I\'d highly recommend Chloe to anyone thinking of selling their property.',
          },
          {
            client: 'Seller of property in South Yarra, VIC',
            content: 'Chloe is everything you want and need in an agent. Absolutely amazing. She offered us valuable advice on how to best present our property to maximise our sale which indeed it did. Impeccable communication throughout our campaign, extremely prompt when answering any questions and absolutely supportive to our needs. I would highly recommend choosing Chloe to sell your property. 10 Stars!!!',
          },
          {
            client: 'Seller of apartment in South Yarra, VIC',
            content: 'Chloe did a great job selling our property, She is very knowledgeable and professional, always kept us informed with latest update.  She has a great understanding of the market, always available for queries and kind reassurance.',
          },
          {
            client: 'Seller of apartment in Burwood, VIC',
            content: 'Excellent service and staff. Original plan was for us to rent out the property but changes in circumstances made it so that we had to sell the property. They swiftly adjusted our letting agreement to a sale agreement with no problems. Communication was swift and they can be reached any time and also liaised with the many different parties (solicitors, purchaser, etc) making the whole process from acquiring the property to selling the property a very smooth process. Thank you Chloe and everyone!'
          },
          {
            client: 'Seller of townhouse in Notting Hill, VIC',
            content: 'Excellent service and staff. Original plan was for us to rent out the property but changes in circumstances made it so that we had to sell the property. They swiftly adjusted our letting agreement to a sale agreement with no problems. Communication was swift and they can be reached any time and also liaised with the many different parties (solicitors, purchaser, etc) making the whole process from acquiring the property to selling the property a very smooth process. Thank you Chloe and everyone!',
          },
          {
            client: 'Seller of townhouse in Templestowe Lower, VIC',
            content: 'Choosing an agent can be confusing, Chloe stood out from the crowd from day one. She really ‘walked the talk’ and her extensive knowledge, experience and approach meant we not only achieved a great result, but also always felt confident that we were in good hands. She was hard working, thorough and professional, consistently kept us updated and always seemed to be on hand to answer our questions.',
          },
          {
            client: 'Seller of apartment in Melbourne, VIC',
            content: 'Chloe\'s passion, her knowledge of the area and the market, her attention to detail and communication skills are second to none. Chloe guided us through all phases of our campaign - from property preparation, styling, marketing, pricing and ultimately an extremely successful result at auction. We really couldn\'t be happier and we can\'t recommend her highly enough.',
          },
          {
            client: 'Buyer of house in Doreen, VIC',
            content: 'Chloe Zang is like no other Real Estate Agent I have come across. Her service skills are second to one. I found purchasing our 2nd property an absolute pleasure. She is proficient to deal with, and delivers promptly on any matters without hesitation. She goes above and beyond any Real Estate Agent I have ever come across. I would highly recommend her services. A breath of fresh air! Thank you Chloe',
          },
          {
            client: 'Seller of Apartment in Melbourne, Vic',
            content: "I cannot recommend Chloe highly enough! She is an absolute gem in the world of real estate. From the moment we first met, Chloe demonstrated an unparalleled level of professionalism and genuine care for our needs. Chloe's extensive knowledge of the market was evident throughout our entire selling process. What truly sets Chloe apart is her honesty and transparency. She was always upfront and candid about every prospect. This level of integrity is rare and greatly appreciated in such a competitive market.",
          },
          {
            client: 'Seller of Apartment in West Melbourne, Vic',
            content: "I'm thrilled to give Chloe a well-deserved 5-star review for her exceptional work in selling my apartment at West Melbourne. Chloe's expertise and dedication were evident every step of the way.Chloe's deep understanding of the market allowed her to secure a sale price that exceeded my expectations. Her strategic approach and negotiation skills are truly commendable. She kept me informed throughout the process, ensuring I was comfortable and well-informed."
          },
          {
            client: 'Seller of Townhouse in Burwood East, Vic',
            content: "Chloe is a very professional estate agent we came across. She sold our townhouse within one week which surprised everyone. We recommend homeowners to contact her if you want to sell your property in this tough market."
          },
          {
            client: 'Seller of Apartment in Burwood East, Vic',
            content: "We found Chloe to be very friendly, supportive, and professional. She always was on hand to answer our questions and throughout the campaign she continued to do exactly what she said she was going to do. She gave us helpful advice with a personal approach. I would highly recommend her as an agent."
          },
          {
            client: 'Seller of Apartment in Burwood, Vic',
            content: "Great communication and professionalism. She also negotiated a very great price between two interested buyers. Always kept us up to date. Has excellent contacts regarding home staging etc. Will use again for our other property in the future most definitely! Thank you, Chloe and your team.",
          },
          {
            client: 'Seller of property',
            content: "I’d like to say a big thank you to Chloe for making the experience of selling my property seamless and easier than what I expected.\n"
              + "She was very friendly and professional, and I especially appreciated her consistent and transparent updates throughout the whole process to keep me informed of how everything was progressing as well as answering any questions along the way before signing the contract. She is very honest, knowledgeable, and experienced.\n"
              + "I would highly recommend her!"
          },
          {
            client: 'Seller of property',
            content: "I͏ highly recommend Chloe Zang. Chloe has done a terrific job in selling my property. Her communication was clear and regular, she has worked with multiple buyers and ultimately achieved me the sale price that I was looking for in under a month. She delivered everything that she said. She is energetic, upfront, honest, and approachable. I'd highly recommend Chloe to anyone thinking of selling their property.",
          },
          {
            client: 'Seller of property',
            content: "Chloe is everything you want and need in an agent. Absolutely amazing. She offered us valuable advice on how to best present our property to maximise our sale which indeed it did. Impeccable communication throughout our campaign, extremely prompt when answering any questions and absolutely supportive to our needs. I would highly recommend choosing Chloe to sell your property. 10 Stars!!!",
          },
          {
            client: 'Seller of Apartment in Burwood, Vic',
            content: "Chloe did a great job selling our property, she is very knowledgeable and professional, always kept us informed with latest update.  She has a great understanding of the market, always available for queries and kind reassurance.",
          },
          {
            client: 'Seller of Townhouse in Notting Hill, Vic',
            content: "Excellent service and staff. Original plan was for us to rent out the property but changes in circumstances made it so that we had to sell the property. They swiftly adjusted our letting agreement to a sale agreement with no problems. Communication was swift and they can be reached any time and also liaised with the many different parties (solicitors, purchaser, etc) making the whole process from acquiring the property to selling the property a very smooth process. Thank you, Chloe, and everyone!",
          },
          {
            client: 'Seller of Townhouse in Templestowe Lower, Vic',
            content: "Choosing an agent can be confusing, Chloe stood out from the crowd from day one. She really ‘walked the talk’ and her extensive knowledge, experience and approach meant we not only achieved a great result, but also always felt confident that we were in good hands. She was hard working, thorough and professional, consistently kept us updated and always seemed to be on hand to answer our questions.",
          },
          {
            client: 'Seller of Apartment in Melbourne, Vic',
            content: "Chloe's passion, her knowledge of the area and the market, her attention to detail and communication skills are second to none. Chloe guided us through all phases of our campaign - from property preparation, styling, marketing, pricing and ultimately an extremely successful result at auction. We really couldn't be happier and we can't recommend her highly enough.",
          },
        ],
      },
      gavin_liu: {
        img: 'assets/img/team/gavin_liu_240.jpg',
        name: 'Gavin Liu',
        data: [
          {
            client: 'Seller of property',
            content: 'Gavin shows his excellent sales skills to help me get a very high offer from the purchaser. Well done. Highly recomend Gavin and his team.',
          },
          {
            client: 'Buyer of property',
            content: 'Gavin is a very professional agent. He presents his experience during the sales. As a buyer from Hongkong, I highly recommend him to the people who wants to buy properties in Melbourne.'
          },
          {
            client: 'Seller of house in Point Cook, VIC',
            content: 'Gavin is very easy to deal with, reliable, professional and full of passion. Appreciate his effort and would recommend him to our friends and family.',
          },
          {
            client: 'Seller of residential land in Tarneit, VIC',
            content: 'Gavin sold my property and had an amazing result. He made the process so smoothly and we highly recommend him to anyone who wants to sell'
          },
          {
            client: 'Seller of apartment in Melbourne, VIC',
            content: 'Gavin is the best who help me sell my city apartment and get the price range I want . High recommend this professional and knowledgeable sale!'
          },
          {
            client: 'Seller of house in Tarneit, VIC',
            content: 'Gavin has provided very professional and nice services! I will highly recommend people who want to sell their property.'
          },
          {
            client: 'Seller of house in Point Cook, VIC',
            content: 'Gavin & the team at Hoyee International had been outstanding, just a dream to deal with.\n' +
              '\n' +
              'I really appreciate they would go 10 steps beyond the standard procedure to make things happen.\n' +
              '\n' +
              'Your work is so great I think others could benefit from learning about it.\n' +
              '\n' +
              '100% that I will recommend you to all of my family & friends.\n' +
              '\n' +
              'Thank you and we are fortunate to have you as our agent.'
          },
          {
            client: 'Seller of House in Point Cook, Vic',
            content: "Sold my point cook property in 3 days, Gavin is the best agent in my mind, he delivered as promised: bring me unconditional offer with the price I want.",
          },
          {
            client: 'Seller of House in Point Cook, Vic',
            content: "Thanks, Gavin! You reached the price which we want in this tough market! Appreciated!",
          },
          {
            client: 'Seller of property',
            content: "Gavin shows his excellent sales skills to help me get a very high offer from the purchaser. Well done. Highly recommend Gavin and his team.",
          },
          {
            client: 'Seller of House in Point Cook, Vic',
            content: "Gavin is very easy to deal with, reliable, professional, and full of passion. Appreciate his effort and would recommend him to our friends and family.",
          },
          {
            client: 'Seller of Residential Land in Tarneit, Vic',
            content: "Gavin sold my property and had an amazing result. He made the process so smoothly and we highly recommend him to anyone who wants to sell.",
          },
          {
            client: 'Seller of House in Tarneit, Vic',
            content: "Gavin has provided very professional and nice services! I will highly recommend people who want to sell their property.",
          },
          {
            client: 'Seller of Apartment in Melbourne, Vic',
            content: "Gavin is the best who help me sell my city apartment and get the price range I want. High recommends this professional and knowledgeable sale!",
          },
          {
            client: 'Seller of House in Point Cook, Vic',
            content: "Gavin & the team at Hoyee International had been outstanding, just a dream to deal with."
            + '\n\n'
            + "I really appreciate they would go 10 steps beyond the standard procedure to make things happen."
            + '\n\n'
            +  "Your work is so great I think others could benefit from learning about it."
            + '\n\n'
            + "100% that I will recommend you to all of my family & friends."
            + '\n\n'
            + "Thank you and we are fortunate to have you as our agent."
          },
        ]
      },
      michael_pang: {
        img: 'assets/img/team/michael_pang_240.jpg',
        name: 'Michael Pang',
        data: [
          {
            client: 'Buyer of apartment in Melbourne, VIC',
            content: 'It was great dealing with Michael - from the start and throughout the process Michael was really responsive, attended to all my queries and concerns, and most definitely exceed my expectations in terms of attendance at inspections, answering questions promptly (Monday-Sunday 24/7 almost), following up the vendor, and making sure the settlement was smooth and easy. I really appreciate Michael\'s hard work and proactiveness, it\'s always great to work with people who are best in what they do.',
          },
          {
            client: 'Seller of apartment in Melbourne, VIC',
            content: 'friendly and helpful'
          },
          {
            client: 'Buyer of apartment in Southbank, VIC',
            content: 'Michael helped us in buying our first home. Very genuine person and always responded to our queries very quickly.'
          },
          {
            client: 'Buyer of apartment in Burwood, VIC',
            content: 'Michael was always available to answer my questions when I had them during the whole process',
          },
          {
            client: 'Seller of apartment in Burwood, VIC',
            content: 'Michael presented a strong analysis of the market, the clients requirements, and he was able to guide the sales process to a successful conclusion. His professional approach, experience and understanding provided confidence throughout the entire campaign.\n' +
              '\n' +
              'We would highly recommend Michael and hope to work with him again in the future.',
          },
          {
            client: 'Seller of apartment in Melbourne, VIC',
            content: 'Michael is extremely knowledgeable and professional and communicated with us effectively across the whole journey of selling my apartment. He consistently kept to his commitment with a phone call at all hours of the day to close the loop on a conversation or update me on a potential new buyer. He was confident in securing a price within my range and he was able to find a couple who will enjoy the place as much as I have. Thanks!'
          },
          {
            client: 'Seller of Apartment in Burwood, Vic',
            content: "Michael presented a strong analysis of the market, the client’s requirements, and he was able to guide the sales process to a successful conclusion. His professional approach, experience and understanding provided confidence throughout the entire campaign."
            + '\n\n'
            + "We would highly recommend Michael and hope to work with him again in the future."
          },
          {
            client: 'Seller of Apartment in Melbourne, Vic',
            content: "Friendly and helpful.",
          },
          {
            client: 'Seller of Apartment in Melbourne, Vic',
            content: "Michael is extremely knowledgeable and professional and communicated with us effectively across the whole journey of selling my apartment. He consistently kept to his commitment with a phone call at all hours of the day to close the loop on a conversation or update me on a potential new buyer. He was confident in securing a price within my range and he was able to find a couple who will enjoy the place as much as I have. Thanks!",
          }
        ]
      },
      sandeep_sharma: {
        img: 'assets/img/team/sandeep_sharma_240.jpg',
        name: 'Sandeep Sharma',
        data: [
          {
            client: 'Seller of residential land in Tarneit, VIC',
            content: 'We would like to thank you for the wonderful service that you provided to us, when selling our house at tarneit. You made it so easy for us and got the price we wanted. You kept us updated all the way through and was very proactive finding the buyers. Your local knowledge and client/customer relationship makes you the successful agent. we would highly recommend anyone to contact sandeep to assist them with the purchase or sale of their home.'
          },
          {
            client: 'Seller of house in Werribee, VIC',
            content: 'Great experience selling with Sandeep. Very knowledgeable about the local market and provided valuable advice, the whole process was simple, easy, and transparent. Would recommend it to everyone.'
          },
          {
            client: 'Seller of house in Truganina, VIC',
            content: 'I would like to thank Sandeep Sharma for his expertise in selling our property. His approach was professional and positive. I appreciate the fact that he took time to take buyers through to open inspections during these tough times and kept me fully informed throughout the entire process. It was an exciting experience and would highly recommend Sandeep to any prospective Vendor. I couldn\'t have been more impressed with the service I received from Sandeep when selling my property.'
          }
        ]
      },
      frankie_leung: {
        img: 'assets/img/team/frankie_leung_128.jpg',
        name: 'Frankie Leung',
        data: [
          {
            client: 'Buyer of apartment in Melbourne, VIC',
            content: 'Frankie is truly a very helpful and knowledgeable property agent who helped me all the way out to secure the apartment that I wished to purchase. Although I was physically overseas that time, he assisted me all the way through to show the unit to my daughter and explained to me from A to Z. He is professional in handling the deal and yes, he is definitely a person you can rely on to search on the property you want in Melbourne. Thank you very much, Frankie!'
          },
          {
            client: 'Buyer of apartment in Melbourne, VIC',
            content: 'His knowledge was vast , he was aware of everything and his response time was really fast and agile.'
          },
        ]
      }
    }
  }

  getFeedBacks(agent) {
    const result = [];

    // Function to shuffle an array
    const shuffleArray = array => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    if (!agent) {
      // Iterate through each agent and shuffle their feedbacks
      Object.values(this.feedbacks).forEach(feedback => {
        shuffleArray(feedback.data); // Shuffle each agent's feedbacks
        result.push(...feedback.data.map(f => ({ ...feedback, data: f }))); // Spread and restructure feedbacks
      });

      shuffleArray(result); // Optionally shuffle the entire result array again
    } else {
      console.log('agent', agent)
      if(this.feedbacks[agent]) {
        result.push(this.feedbacks[agent]);
      }
    }

    console.log(result)
    return result;
  }

  render() {

    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'

    return (
      this.getFeedBacks(this.props.agent).length > 0 &&
      <div className="ltn__testimonial-area bg-image-top pt-15 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                {/*<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Testimonial</h6>*/}
                <h1 className="section-title">Clients Feedback</h1>
              </div>
            </div>
          </div>
          <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
            {this.props.agent && this.getFeedBacks(this.props.agent).map((item, i) => {
              return item.data.map((feedback, j) => {
                console.log('feedback', feedback)
                return <div className="col-lg-4" key={i + '-' + j}>
                  <div className="ltn__testimonial-item ltn__testimonial-item-7">
                    <div className="ltn__testimoni-info">
                      <p>
                        <i className="flaticon-left-quote"/>
                        {createMarkup(feedback.content)}
                      </p>
                      <div className="product-ratting">
                        <ul>
                          <li><a href="#"><i className="fas fa-star" /></a></li>
                          <li><a href="#"><i className="fas fa-star" /></a></li>
                          <li><a href="#"><i className="fas fa-star" /></a></li>
                          <li><a href="#"><i className="fas fa-star" /></a></li>
                          <li><a href="#"><i className="fas fa-star" /></a></li>
                          {/*<li><a href="#"><i className="fas fa-star-half" /></a></li>*/}
                          {/*<li><a href="#"><i className="fas fa-star" /></a></li>*/}
                          {/*<li className="review-total"> <a href="#"> ( 1 Reviews )</a></li>*/}
                        </ul>
                      </div>
                      <div className="ltn__testimoni-info-inner">
                        <div className="real-estate-agent ltn__testimoni-img">
                          <img
                            src={publicUrl + item.img}
                            alt={item.name}
                          />
                        </div>
                        <div className="ltn__testimoni-name">
                          <h6>{item.name}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              })
            })}
            {!this.props.agent && this.getFeedBacks(this.props.agent).map((item, i) => {
              return <div className="col-lg-4" key={i}>
                <div className="ltn__testimonial-item ltn__testimonial-item-7">
                  <div className="ltn__testimoni-info">
                      <i className="flaticon-left-quote"/>
                      {item.data.content}
                    <div className="product-ratting">
                      <ul>
                        <li><a href="#"><i className="fas fa-star" /></a></li>
                        <li><a href="#"><i className="fas fa-star" /></a></li>
                        <li><a href="#"><i className="fas fa-star" /></a></li>
                        <li><a href="#"><i className="fas fa-star" /></a></li>
                        <li><a href="#"><i className="fas fa-star" /></a></li>
                        {/*<li><a href="#"><i className="fas fa-star-half" /></a></li>*/}
                        {/*<li><a href="#"><i className="fas fa-star" /></a></li>*/}
                        {/*<li className="review-total"> <a href="#"> ( 1 Reviews )</a></li>*/}
                      </ul>
                    </div>
                    <div className="ltn__testimoni-info-inner">
                      <div className="ltn__testimoni-img">
                        <img
                          src={publicUrl + item.img}
                          alt={item.name}
                        />
                      </div>
                      <div className="ltn__testimoni-name">
                        <h6>{item.name}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default Testimonial
