import request, { BASE_URL } from './request';


export const updateHeaders = () => {
  const apiKey = process.env.REACT_APP_API_KEY;
  const accessToken = process.env.REACT_APP_ACCESS_TOKEN;
  console.log('apiKey', apiKey);
  console.log('accessToken', accessToken);
  request.defaults.headers.common['X-Api-Key'] = apiKey;
  request.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
}

/**
 * @param params
 * @returns {Promise<axios.AxiosResponse<ResType>>}
 */
export const fetchPropertiesSale = (params) => {
  const finalPrams = {
    params: {...params}
  }
  return request.get('api/properties/sale', finalPrams);
}

/**
 * @param params {id: number, lifeid: number}
 * @returns {Promise<axios.AxiosResponse<ResType>>}
 */
export const fetchPropertyOpenHomes = (params) => {
  return request.get(`api/properties/${params.id}/sale/${params.lifeid}/openHomes`);
}

/**
 * @param params {propertiesIds: number[], startingAfter: number}
 * @returns {Promise<axios.AxiosResponse<ResType>>}
 */
export const fetchPropertiesOpenHomes = async (params) => {
  const finalPrams = {
    params: {...params}
  }
  return request.get(`api/openHomes`, finalPrams);
}

/**
 * @param params
 * @returns {Promise<axios.AxiosResponse<ResType>>}
 */
export const fetchResidentialPropertiesSold = (params) => {
  const finalPrams = {
    params: {...params}
  }
  return request.get('api/properties/residential/sale/sold', finalPrams);
}

export const fetchPropertySale = (id, params) => {
  const finalPrams = {
        params: {...params}
    }
  // return request.get(`api/properties/${id}`, finalPrams);
  return request.get(`api/properties/residential/sale/${id}`, finalPrams);
}

export const fetchResidentialPropertySale = (id, params) => {
  const finalPrams = {
    params: {...params}
  }
  return request.get(`api/properties/residential/sale/${id}`, finalPrams);
}

/**
 * @param params
 * @returns {Promise<axios.AxiosResponse<ResType>>}
 */
export const fetchPropertiesLease = (params) => {
  const finalPrams = {
    params: {...params}
  }
  return request.get('/ftp/properties/lease', finalPrams);
}

/**
 * @param params
 * @returns {Promise<axios.AxiosResponse<ResType>>}
 */
export const fetchUsers = (params) => {
    const finalPrams = {
        params: {...params}
    }
    // console.log('fetchUsers', finalPrams);
  return request.get('api/account/users', finalPrams);
}

/**
 * Fetches the size of the image from the proxy server
 * @param {string} imageUrl - The URL of the image to check
 * @returns {Promise<axios.AxiosResponse<ResType>>}
 */
export const fetchImageSize = async (imageUrl) => {
  const fullUrl = `${BASE_URL === '/'
    ? `check-image-size?url=${imageUrl}`
    : `${BASE_URL}/check-image-size?url=${imageUrl}`}`

    try {
      const response = await request.get(fullUrl);
      return response;
    } catch (error) {
      console.error('Error fetching image size:', error.message);
      if (error.response) {
        console.error('Error status:', error.response.status);
        console.error('Error data:', error.response.data);
      }
      return null;
    }
};

export const imageProxyUrl = (url) => {
  return `${BASE_URL === '/' 
    ? `image-proxy?url=${url}`
    : `${BASE_URL}/image-proxy?url=${url}`}`;
}

/**
 * Sends an email with the given form data
 * Property keys of formData depend on form fields.
 * @param {Object} formData
 * @param {string} formData.name
 * @param {string} formData.email
 * @param {string} formData.service
 * @param {string} formData.phone
 * @param {string} formData.address
 * @param {string} formData.message
 * @param {string} title
 * @returns {Promise<axios.AxiosResponse<ResType>>}
 */
export const sendEmail = (formData, title) => {
  console.log('sendEmail', formData, title)
  return request.post('/send-email', `title=${title}&${formData}`);
};
