import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form';
import Map from './section-components/map';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import {Link} from "react-router-dom";
import Comments from "./blog-components/comments";
import { Helmet } from 'react-helmet';

const ProjectPast = () => {
  let publicUrl = process.env.PUBLIC_URL + '/'

  return <div>
    <Helmet>
      <title>Hoyee Property Group | Past Projects | Australia</title>
    </Helmet>

    <Navbar/>
    <PageHeader headertitle="" subheader="Past Projects"/>

    <>
      <div className="ltn__blog-details-wrap">
        <div className="ltn__page-details-inner ltn__blog-details-inner">
          <h1 className="ltn__blog-title" style={{textAlign: "center"}}>Past Projects</h1>
          <h3><strong>Arden Gardens – 111 Canning Street, North Melbourne VIC 3051
          </strong></h3>
          <p>
            Year completed: 2019
          </p>
          <p>
            No. Apartments: 299
          </p>

          <div style={{textAlign: 'center'}}>
            <img src={publicUrl + 'assets/img/project-arden-gardens/1.jpg'}
                 style={{width: '800px'}}
                 alt="Image"/>
          </div>

          <div style={{textAlign: 'center', paddingTop: '20px'}}>
            <img src={publicUrl + 'assets/img/project-arden-gardens/2.jpg'}
                 style={{width: '800px'}}
                 alt="Image"/>
          </div>

          <h3><strong>Summit</strong> – 9-11 Williamsons Road, Doncaster VIC 3108</h3>
          <p>
            Year completed: 2020
          </p>
          <p>
            No. Apartments: 108
          </p>
          <div style={{textAlign: 'center'}}>
            <img src={publicUrl + 'assets/img/project-summit-apartments/1.jpg'}
                 style={{width: '800px'}}
                 alt="Image"/>
          </div>

          <div style={{textAlign: 'center', paddingTop: '20px'}}>
            <img src={publicUrl + 'assets/img/project-summit-apartments/1.jpg'}
                 style={{width: '800px'}}
                 alt="Image"/>
          </div>

          <h3><strong>Portrait Carnegie</strong> – 401-407 Neerim Road, Carnegie VIC 3163</h3>
          <p>
            Year completed: 2018
          </p>
          <p>
            No. of apartments: 62
          </p>
          <div style={{textAlign: 'center'}}>
            <img src={publicUrl + 'assets/img/project-portrait-carnegie/1.jpg'}
                 style={{width: '800px'}}
                 alt="Image"/>
          </div>

          <h3><strong>Live City (Stage 1)</strong> – 124-188 Ballarat Road, Footscray VIC 3011</h3>
          <p>
            Year completed: 2020
          </p>
          <p>
            No. of apartments: 208
          </p>
          <div style={{textAlign: 'center'}}>
            <img src={publicUrl + 'assets/img/project-live-city-exterior/1.jpg'}
                 style={{width: '800px'}}
                 alt="Image"/>
          </div>

          <h3><strong>HUE</strong> – 37-41 Warrigal Road, Hughesdale VIC 3166</h3>
          <p>
            Year completed: 2019
          </p>
          <p>
            No. of apartments: 72
          </p>
          <div style={{textAlign: 'center'}}>
            <img src={publicUrl + 'assets/img/project-hue/1.jpg'}
                 style={{width: '800px'}}
                 alt="Image"/>
          </div>

          <h3><strong>Claremont Manor</strong> – 7 Claremont Street, South Yarra VIC 3141</h3>
          <p>
            Year completed: 2016
          </p>
          <p>
            No. of Apartments: 318
          </p>
          <div style={{textAlign: 'center'}}>
            <img src={publicUrl + 'assets/img/project-claremont-manor/1.jpg'}
                 style={{width: '800px'}}
                 alt="Image"/>
          </div>

          <h3><strong>Hampstead Park (Stage 1)</strong> – 82-96 Hampstead Road, Maidstone VIC 3012</h3>
          <p>
            Year completed: 2021
          </p>
          <p>
            No. of properties: 75 Townhouses
          </p>
          <div style={{textAlign: 'center'}}>
            <img src={publicUrl + 'assets/img/project-hampstead-park/1.jpg'}
                 style={{width: '800px'}}
                 alt="Image"/>
          </div>

          <h3><strong>Flagstaff Green Apartments</strong> – 23 Batman Street, West Melbourne VIC 3003</h3>
          <p>
            Year completed: 2019
          </p>
          <p>
            No. Apartments: 220
          </p>
          <div style={{textAlign: 'center'}}>
            <img src={publicUrl + 'assets/img/project-flagstaff-green-apartments/1.jpg'}
                 style={{width: '800px'}}
                 alt="Image"/>
          </div>

          <h3><strong>Trilby</strong> – 466 Smith Street, Collingwood VIC</h3>
          <p>
            Year completed: 2018
          </p>
          <p>
            No. Apartments: 106
          </p>
          <div style={{textAlign: 'center'}}>
            <img src={publicUrl + 'assets/img/project-trilby/1.jpg'}
                 style={{width: '800px'}}
                 alt="Image"/>
          </div>

          <h3><strong>Lilix</strong> – 51-59 Thistlethwaite Street, South Melbourne</h3>
          <p>
            Year completed: 2019
          </p>
          <p>
            No. Apartments: 94
          </p>
          <div style={{textAlign: 'center'}}>
            <img src={publicUrl + 'assets/img/project-lilix/1.jpg'}
                 style={{width: '800px'}}
                 alt="Image"/>
          </div>

          <h3><strong>Maison</strong> – 127 Tyner Road, Wantirna South VIC 3152</h3>
          <p>
            Year completed: 2020
          </p>
          <p>
            No. of townhouses: 28
          </p>
          <div style={{textAlign: 'center'}}>
            <img src={publicUrl + 'assets/img/project-maison/1.jpg'}
                 style={{width: '800px'}}
                 alt="Image"/>
          </div>

          <h3><strong>Altus</strong> – 260-262 Burwood Highway, Burwood VIC 3125</h3>
          <p>
            Year completed: 2020
          </p>
          <p>
            No. of townhouses: 44
          </p>
          <div style={{textAlign: 'center'}}>
            <img src={publicUrl + 'assets/img/project-altus/1.jpg'}
                 style={{width: '800px'}}
                 alt="Image"/>
          </div>

          <h3><strong>11 Sheahans Road, Templestowe Lower VIC 3107</strong></h3>
          <p>
            Year completed: 2021
          </p>
          <p>
            No. of luxury townhouses: 2
          </p>
          <div style={{textAlign: 'center'}}>
            <img src={publicUrl + 'assets/img/project-11-sheahans-road/1.png'}
                 style={{width: '800px'}}
                 alt="Image"/>
          </div>
        </div>
      </div>
    </>
    <ContactForm/>

    <ContactInfo/>
    {/*<Map />*/}
    {/*<CallToActionV1 />*/}
    <Footer/>
  </div>
}

export default ProjectPast
