import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Link as ScrollLink} from 'react-scroll';
import PropertyManagementServices from '../PropertyManagementServices';

class Navbar extends Component {

  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    let imgattr = 'logo'
    let anchor = '#'
    return (
      <div>
        <header
          className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent gradient-color-4---">
          <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white"
               style={{paddingTop: '1.2rem', paddingBottom: '1.2rem', backgroundColor: '#fff'}}
          >
            <div className="container"
                 style={{paddingLeft: '5%', paddingRight: '5%', maxWidth: '1300px'}}
            >
              <div className="row">
                <div className="col">
                  <div className="site-logo-wrap">
                    <div className="site-logo go-top">
                      <Link to="/">
                        <img src={publicUrl + "assets/img/logo.png"} alt="Logo"
                             style={{height: '3rem'}}
                        />
                      </Link>
                    </div>
                    <div className="get-support clearfix d-none">
                      <div className="get-support-icon">
                        <i className="icon-call"/>
                      </div>
                      <div className="get-support-info">
                        <h6>Get Support</h6>
                        <h4><a href="tel:+123456789">123-456-789-10</a></h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col header-menu-column">
                  <div className="header-menu d-none d-xl-block go-top">
                    <nav>
                      <div className="ltn__main-menu">
                        <ul>
                          {/*<li className="menu-icon"><a href="/">Home</a></li>*/}
                          <li className="menu-icon">
                            <Link to="#"
                                  onClick={(e) => e.preventDefault()}
                            >
                              Sell
                            </Link>
                            <ul className="sub-menu">
                              <li><Link to="/sell-with-us">Sell with us</Link></li>
                              <li><Link to="/appraisal">Property Appraisal</Link></li>
                              <li><Link to="/property-grid/sold">Sold</Link></li>
                            </ul>
                          </li>
                          <li className="menu-icon"><Link to="/property-grid/buy">Buy</Link></li>
                          <li className="menu-icon">
                            <Link to="#"
                                  onClick={(e) => e.preventDefault()}
                            >
                              Rent
                            </Link>
                            <ul className="sub-menu">
                              <li><Link to="/property-management-services">Property Management Services</Link></li>
                              <li><Link to="/property-grid/rent">Recently Listed</Link></li>
                              <li><Link to="/render-forms-and-resources">Renter Forms and Resources</Link></li>
                              <li><Link to="/appraisal-rental">Free Rental Appraisal</Link></li>
                            </ul>
                          </li>
                          <li className="menu-icon">
                            <Link to="#"
                                  onClick={(e) => e.preventDefault()}
                            >
                              Projects
                            </Link>
                            <ul className="sub-menu">
                              <li><Link to="/hoyee-project">Hoyee Projects</Link></li>
                              <li><Link to="/project-current">Current Projects</Link></li>
                              <li><Link to="/settlement-management">Settlement Management</Link></li>
                              <li><Link to="/project-past">Past Projects</Link></li>
                              {/*<li><Link to="/project-selling">Projects Selling</Link></li>*/}
                              {/*<li><Link to="/project-leasing">Projects Leasing</Link></li>*/}
                            </ul>
                          </li>
                          <li>
                            <Link to="#"
                                  onClick={(e) => e.preventDefault()}
                            >
                              About
                            </Link>
                            <ul className="sub-menu">
                              <li><Link to="/about">Our Story</Link></li>
                              <li><Link to="/service">Services</Link></li>
                              <li><Link to="/team">Team</Link></li>
                              <li><Link to="/contact">Contact</Link></li>
                            </ul>
                          </li>
                          <li className="special-link">
                            {/*<Link to="/add-listing">Add Listing</Link>*/}
                            <Link to="/appraisal" style={{fontWeight: 600}}>Book A Free Appraisal</Link>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="col--- ltn__header-options ltn__header-options-2 ">
                  {/* Mobile Menu Button */}
                  <div className="mobile-menu-toggle d-xl-none">
                    <a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
                      <svg viewBox="0 0 800 600">
                        <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                              id="top"/>
                        <path d="M300,320 L540,320" id="middle"/>
                        <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                              id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "/>
                      </svg>
                    </a>
                  </div>
                </div>
                {
                  this.props.innerNavigation &&
                  <div className="ltn__header-inner-menu">
                    <ul style={{listStyleType: 'none', display: 'flex', padding: 0, margin: 0}}>
                      <li style={{marginRight: '10px', cursor: "pointer"}}>
                        <ScrollLink
                          activeClass="active"
                          to="photos"
                          spy={true}
                          smooth={true}
                          duration={200}
                          offset={-270}
                        >
                          Photos
                        </ScrollLink>
                      </li>
                      <li>/</li>
                      <li style={{marginLeft: '10px', paddingRight: '10px', cursor: "pointer"}}>
                        <ScrollLink
                          activeClass="active"
                          to="floorplan"
                          spy={true}
                          smooth={true}
                          duration={200}
                          offset={-270}
                        >
                          Floorplan
                        </ScrollLink>
                      </li>
                      <li>/</li>
                      <li style={{marginLeft: '10px', cursor: "pointer"}}>
                        <ScrollLink
                          activeClass="active"
                          to="description"
                          spy={true}
                          smooth={true}
                          duration={200}
                          offset={-370}
                        >
                          Description
                        </ScrollLink>
                      </li>
                    </ul>
                  </div>
                }
              </div>
            </div>
          </div>
        </header>
        <div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu">
          <div className="ltn__utilize-menu-inner ltn__scrollbar">
            <div className="ltn__utilize-menu-head">
              <div className="site-logo">
                <Link to="/"><img src={publicUrl + "assets/img/logo.png"} alt="Logo"/></Link>
              </div>
              <button className="ltn__utilize-close">×</button>
            </div>
            <div className="ltn__utilize-menu-search-form">
              <form action={"#"}>
                <input type="text" placeholder="Search..."/>
                <button><i className="fas fa-search"/></button>
              </form>
            </div>
            <div className="ltn__utilize-menu">
              <ul>
                <li><a href="/">Home</a></li>
                <li>
                  <Link to="#"
                          onClick={(e) => e.preventDefault()}
                  >
                    Sell
                  </Link>
                  <ul className="sub-menu">
                    <li><Link to="/sell-with-us">Sell with us</Link></li>
                    <li><Link to="/appraisal">Property Appraisal</Link></li>
                    <li><Link to="/property-grid/sold">Sold</Link></li>
                  </ul>
                </li>
                <li><Link to="/property-grid/buy">Buy</Link></li>
                <li>
                  <Link to="#"
                        onClick={(e) => e.preventDefault()}
                  >
                    Rent
                  </Link>
                  <ul className="sub-menu">
                    <li><Link to="/property-management-services">Property Management Services</Link></li>
                    <li><Link to="/property-grid/rent">Recently Listed</Link></li>
                    <li><Link to="/render-forms-and-resources">Renter Forms and Resources</Link></li>
                    <li><Link to="/appraisal-rental">Free Rental Appraisal</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="#"
                        onClick={(e) => e.preventDefault()}
                  >
                    Projects
                  </Link>
                  <ul className="sub-menu">
                    <li><Link to="/hoyee-project">Hoyee Projects</Link></li>
                    <li><Link to="/project-current">Current Projects</Link></li>
                    <li><Link to="/settlement-management">Settlement Management</Link></li>
                    <li><Link to="/project-past">Past Projects</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="#"
                        onClick={(e) => e.preventDefault()}
                  >
                    About
                  </Link>
                  <ul className="sub-menu">
                    <li><Link to="/about">Our Story</Link></li>
                    <li><Link to="/service">Services</Link></li>
                    <li><Link to="/team">Team</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                  </ul>
                </li>
                <li className="special-link" style={{marginLeft: 0}}>
                  {/*<Link to="/add-listing">Add Listing</Link>*/}
                  <Link to="/appraisal" style={{fontWeight: 600}}>Book A Free Appraisal</Link>
                </li>
              </ul>
            </div>
            <div className="ltn__social-media-2">
              <ul>
                <li>
                  <a href="https://facebook.com/Hoyee-International-108206555314774/" title="Facebook" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook-f"/>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/hoyeepropertygroup" title="Instagram" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"/>
                  </a>
                </li>
                <li>
                  <a href="https://linkedin.com/company/hoyeeinternational" title="LinkedIn" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-linkedin"/>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default Navbar
