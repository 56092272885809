import React, { Component } from 'react';
import { sendEmail } from '../../api';
import AlertMessage from '../alert-message';

class AppraisalForm extends Component {
  state = {
    isSubmitting: false,
    alertMessage: '',
    showAlert: false,
  };

  getAppraisalTitle() {
    return this.props.type === 'rent'
      ? 'Contact a HOYEE property expert for the latest market rental appraisal.'
      : 'Contact a HOYEE property expert for the latest market appraisal.';
  }

  componentDidMount() {

    const $ = window.$;

    // Get the form.
    var form = $('#contact-form');

    // Set up an event listener for the contact form.
    $(form).submit((e) => {
      // Stop the browser from submitting the form.
      e.preventDefault();

      this.setState({ isSubmitting: true, showAlert: false });

      // Serialize the form data.
      const formData = $(form).serialize();
      const getEmailTitle = () => {
        if (this.props.type === 'rent') {
          return 'Book a Free Rental Appraisal';
        } else {
          return 'Book a Free Appraisal';
        }
      }
      sendEmail(formData, getEmailTitle())
        .then(response => {
          this.setState({
            alertMessage: 'Email sent successfully',
            showAlert: true,
          });
          setTimeout(() => this.setState({ showAlert: false }), 5000);
          console.log('Email sent successfully:', response.data);
          // Handle success - maybe clear the form or show a success message
        })
        .catch(error => {
          this.setState({
            alertMessage: 'Error sending email. Please call us instead.',
            showAlert: true,
          });
          console.error('Error sending email:', error);
          // Handle error - maybe show an error message to the user
        })
        .finally(() => {
          this.setState({ isSubmitting: false });
        });

      console.log('DEBUG >> formData', formData);
    })
  }

  render() {

    let publicUrl = process.env.PUBLIC_URL+'/'

    const { isSubmitting, alertMessage, showAlert } = this.state;

    return <div className="48x48" style={{paddingTop: '1em', paddingBottom: '2em'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__form-box contact-form-box box-shadow white-bg">
              <h4 className="title-2">
                {this.getAppraisalTitle()}
              </h4>
              <form id="contact-form" method="post">
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input type="text" name="name" placeholder="Enter your name" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-email ltn__custom-icon">
                      <input type="email" name="email" placeholder="Enter email address (Required)" required />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item" >
                      {
                        this.props.type === 'rent'
                        ? <select className="nice-select" name="service">
                            <option>Rental Appraisal</option>
                          </select>
                        : <select className="nice-select" name="service">
                          <option>Sales</option>
                          <option>Property Management</option>
                        </select>
                      }
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-phone ltn__custom-icon">
                      <input type="text" name="phone" placeholder="Enter phone number" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-item input-item-map ltn__custom-icon">
                      <input type="text" name="address" placeholder="Enter Property address" />
                    </div>
                  </div>
                </div>
                <div className="input-item input-item-textarea ltn__custom-icon">
                  <textarea name="message" placeholder="Additional Details" defaultValue={""} />
                </div>
                {/*<p><label className="input-info-save mb-0"><input type="checkbox" name="agree" /> Save my name, email, and website in this browser for the next time I comment.</label></p>*/}
                <div className="btn-wrapper mt-0">
                  <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Sending...' : 'REQUEST AN APPRAISAL'}
                  </button>
                </div>
                <p className="form-messege mb-0 mt-20" />
              </form>
              {showAlert && <AlertMessage message={alertMessage} type={alertMessage.startsWith('Error') ? 'error' : 'success'} />}
            </div>
          </div>
        </div>
      </div>
      {/*<ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />*/}
    </div>
  }
}

export default AppraisalForm
