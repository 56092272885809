import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form';
import Map from './section-components/map';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import {Link} from "react-router-dom";
import Comments from "./blog-components/comments";
import { Helmet } from 'react-helmet';

const SellWithUs = () => {
  let publicUrl = process.env.PUBLIC_URL + '/'

  return <div>
    <Helmet>
      <title>Hoyee Property Group | Sell with Us | Australia</title>
    </Helmet>

    <Navbar/>
    <PageHeader headertitle="" subheader="Settlement Management"/>

    <>
      <div className="ltn__blog-details-wrap">
        <div className="ltn__page-details-inner ltn__blog-details-inner">
          <h1 className="ltn__blog-title" style={{textAlign: "center"}}>Sell with HOYEE</h1>
          {/*<div style={{ textAlign: 'center', width: '50%', margin: '0 auto' }}>*/}
          {/*  <img*/}
          {/*    src={publicUrl + "assets/img/banner/hoyee_projects_banner.png"}*/}
          {/*    alt="Image"*/}
          {/*    style={{*/}
          {/*      display: 'block',*/}
          {/*      margin: 'auto',*/}
          {/*      maxWidth: '100%',*/}
          {/*      height: 'auto'*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}

          <p>
          Choosing the right real estate agent to sell your home is paramount to achieving an exceptional result. As property experts in Melbourne CBD and Metropolitan area, Hoyee Property Group is dedicated to delivering top-tier service and an unparalleled real estate journey.
          </p>
          <p>
          Hoyee Property Group has established best practice benchmarks in Melbourne and are strategically placed to maximise your opportunity to connect with local, interstate and international buyers and reach the best possible sale price for your property.
          </p>
          <p>
          At Hoyee Property Group, we believe that client satisfaction is the only true measure of success. Experience the difference today.
          </p>

          <h1 className="ltn__blog-title" style={{textAlign: "center"}}>RELIABLE | EXPERIENCED | EFFICIENT</h1>

          <h5>What is the most important? – Presentation</h5>
          <p>
          Most people are making decision based upon their first impression, which explains why presentation is crucial to all the positive relationship your home provide to the buyers.
          </p>

          <h5>What is priceless? – Time</h5>
          <p>
          We believe the best price happens in the first 30 days on market. We put everything into that.
          </p>

          <h5>What can marketing influence? – More opportunities, more competition</h5>
          <p>
          A strong marketing campaign can give you the chance to be outstanding and aggressively educate the buyer to stop time wasting and make the decision wisely.
          </p>

          <h5>Why choose us? – Expertise and Professionalism</h5>
          <p>
          An experienced agent who is exceptional in the area of negotiation can influence a buyers perception on value by more than 10%.
          </p>

          <h5>What is the key to success? – Communication</h5>
          <p>
          Efficient communication can save not only the time but also the efforts to achieve the goal. It also helps you to feel in control and stress free. By sufficient communication, you can experience a distinguish and superior sense of selling and buying process.
          </p>
          <p>
          Our System achieves more, the performances are there. We look forward to hearing from you regarding all real estate queries and more than happy to help and assist.
          </p>
        </div>
      </div>
    </>
    <CallToActionV1
      title={'Contact us for free appraisal.'}
      subtitle={''}
      link={'/appraisal'}
      linkText={'Free Appraisal'}
    />
    <Footer/>
  </div>
}

export default SellWithUs
