import { useContext, createContext } from 'react';
import GlobalStore from './globalStore';

class RootStore{
  constructor() {
    this.globalStore = new GlobalStore();
  }
}
const StoresContext = createContext(new RootStore());
const useStore = () => useContext(StoresContext);

export default useStore;
