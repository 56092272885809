import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';

class TeamDetails extends Component {

  render() {

    console.log(
      'TeamDetails',
      this.props.name, this.props.title, this.props.img, this.props.email, this.props.phone,
      this.props.introduction
    )

    let publicUrl = process.env.PUBLIC_URL + '/'

    return <div className="ltn__team-details-area mb-120 mt-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="ltn__team-details-member-info text-center mb-40">
              <div className="team-details-img">
                {
                  this.props.img &&
                  <img src={publicUrl + `${this.props.img}`} alt="Team Member Image"/>
                }
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="ltn__team-details-member-info-details" style={{paddingLeft: '10%', paddingRight: '10%'}}>
              <div className="row">
                <div className="col-lg-6 col-md-8" style={{
                  textAlign: window.innerWidth <= 768 ? 'center' : 'initial'
                }}>
                  <h2>{this.props.name}</h2>
                  <h6 className="text-uppercase ltn__secondary-color">{this.props.title}</h6>
                  <div className={'row flex-row'}>
                    <div className={'col-lg-12'}>
                    <a href="#" title="Email"><i className="fab icon-mail" style={{paddingRight: '1em'}}/>
                      {this.props.email}
                    </a>
                    </div>
                    <div className={'col-lg-12'}>
                    <a href="#" title="Phone"><i className="fab icon-phone-call" style={{paddingRight: '1em'}}/>
                      {this.props.phone}
                    </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-4">
                  <div className="ltn__social-media-3" style={{
                    textAlign: window.innerWidth <= 768 ? 'center' : 'right'
                  }}>
                    <ul>
                      <li><a href="https://facebook.com/Hoyee-International-108206555314774/" title="Facebook" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"/></a></li>
                      <li><a href="https://www.instagram.com/hoyeepropertygroup" title="Instagram" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"/></a></li>
                      <li><a href="https://linkedin.com/company/hoyeeinternational" title="Linkedin" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"/></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div style={{paddingTop: '24px'}} dangerouslySetInnerHTML={{ __html: this.props.introduction }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default TeamDetails
