import React, { useEffect } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ProductSlider from './shop-components/product-slider-v1';
import ShopDetailsSale from './shop-components/shop-details-sale';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useLocation } from 'react-router-dom';
import { fetchPropertySale, fetchPropertyOpenHomes, imageProxyUrl } from '../api';
import ShopDetailsLease from './shop-components/shop-details-lease';
import { fetchProperties, filterLeasingImagesBySize } from '../utility';
import { fetchPropertiesLease } from '../api';
import useStore from '../store';
import './product-details.css';

/**
 * @typedef {Object} ProductDetailsProps
 * @property {'sale' | 'sold' | 'buy' | 'rent'} type
 * @property {Object} property
 * @property {number} id
 */

/**
 * @param {ProductDetailsProps} props
 * @returns {Element}
 * @constructor
 */
const Product_Details = (props  ) => {

    /**
     * @type {Location<H.LocationState>}
     */
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const id = query.get('id');
    const type = query.get('type');

    // Access state
    const { propertyParam } = location.state || {};

    const [property, setProperty] = React.useState(props.location.state?.property || null);
    const [commingInspections, setCommingInspections] = React.useState(props.location.state?.upcomingInspections || null);

    console.log('Product_Details >> props', props, 'id', id, 'type', type);
    console.log('Product_Details >> property', property);

    // Scroll to top
    // For other components, it has been done by unknown implementation.
    // To scroll to top on every route transition
    // https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
    useEffect(() => {
      console.log('Product_Details >> useEffect >> scroll to top');
        window.scrollTo(0, 0);
    }, []);

    const params = new URLSearchParams(window.location.hash.split('?')[1]);
    const propertyId = params.get('id');
    const propertyLifeId = params.get('lifeid');
    const propertyType = params.get('type');

    const noPropertyFound = React.useRef(false);
    const setNoPropertyFound = (value) => {
      noPropertyFound.current = value;
    }
    const { globalStore } = useStore();

    useEffect(() => {
        console.log('Product_Details >> property is null, fetching from API');
        if(!property && propertyType === 'sale') {
          if(!property) {
              fetchPropertySale(id).then((response) => {
                  setProperty(response);
              });
          }
        }
        if(propertyId && propertyLifeId && !commingInspections) {
          fetchPropertyOpenHomes({id: propertyId, lifeid: propertyLifeId}).then((response) => {
            console.log('Product_Details >> fetchPropertyOpenHomes >> response', response);
            const currentTime = new Date();
            console.log('Product_Details >> currentTime', currentTime);
            const filteredInspections = response.items.filter(inspection => {
              const inspectionDate = new Date(inspection.start);
              console.log('Product_Details >> inspectionDate', inspectionDate);
              // Include inspections from today onwards
              return inspectionDate.setHours(0,0,0,0) >= currentTime.setHours(0,0,0,0);
            });
            console.log('Product_Details >> filteredInspections', filteredInspections);
            setCommingInspections(filteredInspections);
          });
        }
    }, [props.location.state, commingInspections, id, property, propertyId, propertyLifeId, propertyType]);

    useEffect(() => {
      console.log('Product_Details >> useEffect >> property', property);
      if(!property && propertyType === 'rent') {
        if (propertyId) {
          const propertiesLeasingPromise = fetchProperties(fetchPropertiesLease);
          propertiesLeasingPromise
            .then(async propertiesLeasing => {
              console.log('DEBUG >> propertiesLeasing', propertiesLeasing);
              if (!propertiesLeasing || propertiesLeasing.length === 0) {
                setNoPropertyFound(true);
                return;
              }
              propertiesLeasing.sort((a, b) => {
                const dateA = new Date(a.dateAvailable[0]);
                const dateB = new Date(b.dateAvailable[0]);
                return dateB - dateA;
              });
              propertiesLeasing = await filterLeasingImagesBySize(propertiesLeasing);
              globalStore.allLeasingProperties.length === 0 && globalStore.setLeasingProperties(propertiesLeasing);
              const fetchedProperty = propertiesLeasing.find(p => p.uniqueID[0] === propertyId);
              console.log('Product_Details >> fetchedProperty', fetchedProperty);
              if (fetchedProperty) {
                setProperty(fetchedProperty);
              }
            })
            .catch(error => {
              console.log('Error fetching propertiesLease', error);
            });
        }
      }
    }, []);

    const mapLeasingPropertyPhotos = (property) => {
      const photos = property?.images[0].img
        .filter((img) => img.$.url && img.$.url !== '')
        .map((img) => {
          return {url: img.$.url}
        });
      console.log('mapLeasingPropertyPhotos >> photos', photos);
      return photos;
    }

    const mapLeasingPropertyPhotosWithProxyFix = (property) => {
      const photos = property.images[0].img
        .filter((img) => img.$.url && img.$.url !== '')
        .map((img) => {
          return {url: imageProxyUrl(img.$.url)}
        });
      console.log('mapLeasingPropertyPhotos >> photos', photos);
      return photos;
    }

    return <div>
        <Navbar innerNavigation={true}/>
        <PageHeader headertitle="Property Details" customclass="mb-0" />

        { (type?.toLowerCase() === 'sale' || type?.toLowerCase() === 'sold')
          && property?.photos?.length > 0
          && <ProductSlider photos={property?.photos} />
        }
        { (type?.toLowerCase() === 'rent')
          && property?.images[0].img.length > 0
          && <ProductSlider photos={mapLeasingPropertyPhotosWithProxyFix(property)} />
        }

        {
          !property &&
          <div className="skeleton-animation">Loading...</div>
        }
        {
          (type?.toLowerCase() === 'sale' || type?.toLowerCase() === 'sold') && property &&
          <ShopDetailsSale
            id={id}
            property={property}
            type={type}
            paddingTop={property?.photos?.length > 0 ? '' : '90px'}
            inspections={commingInspections} // Pass the inspections data here
          />
        }
        {
          type?.toLowerCase() === 'rent' && property &&
          <ShopDetailsLease property={property} type={type} paddingTop={mapLeasingPropertyPhotos(property)?.length > 0 ? '' : '90px'} />
        }

        <div style={{ marginTop: 'auto' }}>
          <CallToActionV1 />
          <Footer />
        </div>
    </div>
}

export default Product_Details

