import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form';
import Map from './section-components/map';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import {Link} from "react-router-dom";
import Comments from "./blog-components/comments";

const ProjectSelling = () => {
  let publicUrl = process.env.PUBLIC_URL + '/'

  return <div>
    <Navbar/>
    <PageHeader headertitle="" subheader="Projects Selling"/>

    <>
      <div className="ltn__blog-details-wrap">
        <div className="ltn__page-details-inner ltn__blog-details-inner">
          <h1 className="ltn__blog-title" style={{textAlign: "center"}}>Projects Selling</h1>
          <p>HOYEEProjects specialises in the sales
            and marketing of&nbsp;significant residential projects from concept to completion.HOYEEProjects is
            proud to have delivered an impressive&nbsp;track record of results for its clients, achieved by using
            proven&nbsp;strategies, fastidious attention to detail and accurate local&nbsp;market knowledge.</p>
          <p>The Projects team works closely with its developer clients to&nbsp;establish
          the optimal design, branding, marketing and sales&nbsp;release strategies to maximise both the pricing and
          rates of&nbsp;sale for off the plan projects.</p>
          <p>HOYEEProjects market
          projects both nationally and&nbsp;internationally and typically works closely with many of the&nbsp;1200 Ray
          White local and international offices to establish the&nbsp;optimal market pricing and target markets for
          projects.
          </p>
          <p><strong>Andrew Salvo</strong> – Principal Director</p>
          <p>Andrew
          is a highly experienced development professional with&nbsp;a substantial track record in delivering
          large-scale residential&nbsp;projects. He was formerly Development Manager at Salvo Property&nbsp;Group, and
          directly managed the project delivery of more than 3000&nbsp;residential dwellings from site procurement to
          project completion.&nbsp;He plays a key part in the strategic preparation and delivery of&nbsp;successful
          project marketing campaigns. Andrew has key business&nbsp;and client relationships locally and in Asia and
          takes an active role&nbsp;in managing the distribution strategy and network.</p>
          <p><strong>Alex
          Zoumboulis</strong> – Project Sales Director</p>
          <p>With 20 years of experience,
          Alex has been a Director of&nbsp;two major real estate groups, and has held both senior and&nbsp;executive
          level management positions within publicly listed&nbsp;corporate real estate environments.&nbsp;Alex brings
          with him a high level of sales performance and&nbsp;commercial acumen. He is directly involved in
          developing&nbsp;each project sales strategy, and directly manages both our&nbsp;sales team and delivery of
          each marketing and project sales&nbsp;campaign.</p>
        </div>
      </div>
    </>
    <ContactForm/>

    <ContactInfo/>
    {/*<Map />*/}
    {/*<CallToActionV1 />*/}
    <Footer/>
  </div>
}

export default ProjectSelling
