import React from 'react';
import './alert-message.css'; // Assuming you have a separate CSS file for styles

const AlertMessage = ({ message, type }) => {
  if (!message) return null;

  const alertClass = `alert ${type === 'error' ? 'alert-error' : 'alert-success'}`;

  return (
    <div className={alertClass}>
      {message}
    </div>
  );
};

export default AlertMessage;
