import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV4 extends Component {

  render() {

    let publicUrl = process.env.PUBLIC_URL + '/'

    return <div className="ltn__about-us-area pt-70 pb-90 go-top" style={{paddingLeft: '10%', paddingRight: '10%'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 align-self-center">
            <div className="about-us-img-wrap about-img-left">
              <img src={publicUrl + "assets/img/team/about_us_team.jpg"} alt="About Us Image"/>
              <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                {/*<div className="ltn__video-img ltn__animation-pulse1">*/}
                  {/*<img src={publicUrl + "assets/img/bg/about_us_01.jpg"} alt="video popup bg image"/>*/}
                  {/*<a className="ltn__video-icon-2 ltn__video-icon-2-border---"*/}
                  {/*   href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0"*/}
                  {/*   data-rel="lightcase:myCollection">*/}
                  {/*  <i className="fa fa-play"/>*/}
                  {/*</a>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-us-info-wrap">
              <div className="section-title-area ltn__section-title-2--- mb-20">
                {/*<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Story</h6>*/}
                <h1 className="section-title">
                  Our Story
                  <span>.</span>
                </h1>
                <p>
                  Established in 2015, Hoyee Property Group has emerged as a prominent Real Estate Advisory and Property Development Company specializing in the Melbourne CBD and Metropolitan area. Our commitment lies in offering comprehensive services within a unified framework. Working with a proficient team of industry experts, we have demonstrated a consistent ability to enhance transactional value and translate sales projections into reality.
                </p>
              </div>
              <ul className="ltn__list-item-half clearfix">
                <li>
                  <img src = {publicUrl + "assets/fonts/icon_design.svg"} />
                  Sales
                </li>
                <li>
                  <img src = {publicUrl + "assets/fonts/icon_leaf.svg"} />
                  Project Marketing
                </li>
                <li>
                  <img src = {publicUrl + "assets/fonts/icon_health.svg"} />
                  Property Management
                </li>
                {/*<li>*/}
                {/*  <img src = {publicUrl + "assets/fonts/icon_calendar.svg"} />*/}
                {/*  Complete 24/7 Security*/}
                {/*</li>*/}
              </ul>
              <div className="ltn__callout bg-overlay-theme-05  mt-30">
                <p>
                  "Above and Beyond | Imagine the Possibilities"
                </p>
              </div>
              <div className="btn-wrapper animated">
                <Link to="/team" className="theme-btn-1 btn btn-effect-1">OUR TEAM</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default AboutV4
